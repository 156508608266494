import { Input, Space, Table, Button } from "antd";
import React, { useState } from "react";
import { SearchOutlined } from "@ant-design/icons";

const TableCustom = (props) => {
  const {
    columns,
    data,
    setParameter,
    except,
    total,
    setPagination,
    loading,
    startInOne,
    noPagination,
  } = props;
  const [pageSize, setPageSize] = useState(10);

  const getColumnSearchProps = (dataIndex) => ({
    
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        {JSON.stringify(dataIndex)}
        {except.indexOf(dataIndex) !== -1 ? (
          "Filter pada kolom ini tidak tersedia"
        ) : (
          <>
            <Input
              placeholder={`Search`}
              value={selectedKeys[0]}
              onChange={(e) =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={() =>
                handleSearch(selectedKeys, confirm, dataIndex)
              }
              style={{ marginBottom: 8, display: "block" }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button
                type="danger"
                onClick={() => handleReset(clearFilters)}
                size="small"
                style={{ width: 90 }}
              >
                Reset
              </Button>
            </Space>
          </>
        )}
      </div>
    ),
  });

  const fixedColumn = columns.map((e) => ({
    ...e,
    ...getColumnSearchProps(e.dataIndex),
  }));

  const handleSearch = (key, f, params) => {
    const data = `&key=${key}`;
    // console.log(data, "==> paramsWOY");
    setParameter(data);
  };

  const handleReset = (clear) => {
    setParameter("");
  };

  const handlePagination = (size, current) => {
    let param = "";
    if (startInOne) {
      param = `?page=${current}&limit=${size}`;
    } else {
      param = `?offset=${current}&limit=${size}`;
    }
    setPagination(param);
  };

  const onShowSizeChange = (current, pageSize) => {
    setPageSize(pageSize);
  };

  return (
    <div>
      <Table
        columns={fixedColumn}
        dataSource={data}
        loading={loading}
        pagination={
          noPagination
            ? false
            : { total: total, pageSize: pageSize, onShowSizeChange }
        }
        onChange={(pagination) => {
          if (startInOne) {
            handlePagination(pagination.pageSize, pagination.current);
          } else {
            handlePagination(pagination.pageSize, pagination.current - 1);
          }
        }}
      />
    </div>
  );
};

export default TableCustom;

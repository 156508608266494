import { Col, Row, Select } from "antd";
import React from "react";

const { Option } = Select;

const DropdownCustom = (props) => {
  const {
    label,
    value,
    placeholder,
    onChange,
    keys,
    datapick,
    flagLevel,
    disabled,
    dropwidth,
    colType,
  } = props;
  const children = datapick;

  const changeHandler = (e) => {
    onChange({ [keys]: e });
  };

  return (
    <Row gutter={14} style={{ marginBottom: "10px" }}>
      <Col span={colType === "underlabel" ? 22 : 6}>
        <label>{label}</label>
      </Col>
      <Col span={dropwidth ? dropwidth : colType === "underlabel" ? 22 : 10}>
        <Select
          allowClear
          disabled={disabled}
          style={{ width: "100%" }}
          onChange={(e) => changeHandler(e)}
          value={value}
          placeholder={placeholder}
        >
          {children.map((e) => (
            <Option value={flagLevel ? e.id : e.uraian}>{e.uraian}</Option>
          ))}
        </Select>
      </Col>
    </Row>
  );
};

export default DropdownCustom;

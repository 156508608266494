import {
    SET_DATA_PARAM_DETAIL,
    SET_DATA_FILTER_DATE_DASHBOARD
} from "../../Type/Dashboard";

const initialState = {
    loadingList: false,
    loadingAction: false,
    loadingObjek: false,
    loadingPost: false,
    listDataBanding: null,
    listDataObjekBanding: null,
    listDataBandingMonitoring: null,
    listDataDokumen: null,
    listDataJaminan: null,
    type: "",
    message: "",
    error: null
}

export default function DashboardReducer(state = initialState, action) {
    switch (action.type) {
        case "SET_DATA_PARAM_DETAIL":
            return {
                ...state,
                type: action.payload.type,
                message: action.payload.message,
                dataParameter: action.payload.data,
                error: action.payload.error
            }
        case SET_DATA_FILTER_DATE_DASHBOARD:
            return {
                ...state,
                filterDate: action.payload
            }
        default:
            return state
    }
}

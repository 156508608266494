import React, { Suspense, lazy, useEffect, useRef } from "react";
import { Redirect, Switch, Route, useHistory } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { MyPage } from "./pages/MyPage";
// import DashboardPage from "./pages/Dashboard/index";
// import DashboardDetail from "./pages/Dashboard/DashboardDetail";
// import Users from "./pages/User&Role/Users";
// import IndexDoctor from "./pages/Doctors/Doctor/IndexDoctor";
// import IndexQueue from "./pages/Doctors/Queue/IndexQueue";
// import IndexRegistration from "./pages/Doctors/Registration/IndexRegistration";
// import IndexSchedule from "./pages/Doctors/Schedule/IndexSchedule";
// import IndexArticle from "./pages/Articles/Article/IndexArticle";
// import IndexTipe from "./pages/TipeDokter/IndexTipe";
import PAGES from './pages'
// import useTimer from "../utils/IdleTimer";
// import * as auth from "./modules/Auth/_redux/authRedux";
// import { useDispatch } from "react-redux";
// import moment from "moment";
import SessionTimeout from "../utils/SessionTimeout";


const GoogleMaterialPage = lazy(() =>
  import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
);
// const ECommercePage = lazy(() =>
//   import("./modules/ECommerce/pages/eCommercePage")
// );
const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);

export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <SessionTimeout />
      {/*<Dialog*/}
      {/*  maxWidth={'md'}*/}
      {/*  disableBackdropClick={true}*/}
      {/*  disableEscapeKeyDown={true}*/}
      {/*  open={open}*/}
      {/*  onClose={handleClose}*/}
      {/*  aria-labelledby="responsive-dialog-title"*/}
      {/*>*/}
      {/*  <DialogTitle id="responsive-dialog-title">{timeoutremain > 0 ? 'Sesi Akan Berhenti' : 'Sesi Berakhir'}</DialogTitle>*/}
      {/*  <DialogContent>*/}
      {/*    <DialogContentText>*/}
      {/*      {*/}
      {/*        timeoutremain > 0 ? 'Dikarena kan tidak ada aktifitas, sesi ini akan berakhir dalam ' + timeoutremain + ' detik' : 'Sesi telah berakhir, Terima Kasih.'*/}
      {/*      }*/}
      {/*    </DialogContentText>*/}
      {/*  </DialogContent>*/}
      {/*  <DialogActions>*/}
      {/*  {*/}
      {/*    timeoutremain > 0 ? (*/}
      {/*      <>*/}
      {/*        <LogoutButton onClick={() => history.push("/logout")} color="primary">*/}
      {/*          Logout*/}
      {/*        </LogoutButton>*/}
      {/*        <ContinueButton onClick={handleClose} color="secondary">*/}
      {/*          Lanjut*/}
      {/*        </ContinueButton>*/}
      {/*      </>*/}
      {/*    ) : (*/}
      {/*      <LogoutButton onClick={ResetSession} color="primary">*/}
      {/*        Mohon Login Kembali*/}
      {/*      </LogoutButton>*/}
      {/*    )*/}
      {/*  }*/}
      {/*  </DialogActions>*/}
      {/*</Dialog>*/}
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        {
          PAGES.map(item => (<ContentRoute {...item} />))
        }
        {/*<ContentRoute path="/dashboard" component={DashboardPage} />*/}
        {/* <ContentRoute path="/dashboard/:id" component={DashboardDetail} /> */}
        {/*<Route path="/users" component={Users} />*/}
        {/*<Route path="/articles/article" component={IndexArticle} />*/}
        {/*<Route path="/tipe/tipedokter" component={IndexTipe} />*/}
        {/*<Route path="/doctors/doctor" component={IndexDoctor} />*/}
        {/*<Route path="/doctors/queue" component={IndexQueue} />*/}
        {/*<Route path="/doctors/registration" component={IndexRegistration} />*/}
        {/*<Route path="/doctors/schedule" component={IndexSchedule} />*/}
        {/*<ContentRoute*/}
        {/*  path="/detail-dashboard/:type/:id/:date1/:date2/:showDate"*/}
        {/*  component={DashboardDetail}*/}
        {/*/>*/}
        <ContentRoute path="/builder" component={BuilderPage} />
        <ContentRoute path="/my-page" component={MyPage} />
        <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        {/* <Route path="/e-commerce" component={ECommercePage} /> */}
        <Route path="/user-profile" component={UserProfilepage} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}

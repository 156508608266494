import React, {useEffect, useRef, useState} from "react";
import IdleTimer from "react-idle-timer";
import {useDispatch, useSelector} from "react-redux";
import * as auth from "../../app/modules/Auth/_redux/authRedux";
import TimeoutDialog from "./TimeoutDialog";
import moment from "moment";

let countdownInterval;
let timeout;

export default function SessionTimeout() {
  const idleTimeout = (1000 * 60) * 30
  const dispatch = useDispatch()
  const stateAuth = useSelector(state => state.auth)
  const { authToken = '' } = stateAuth || {}
  const [timeoutModalOpen, setTimeoutModalOpen] = useState(false);
  const [timeoutCountdown, setTimeoutCountdown] = useState(0);
  const idleTimer = useRef(null);

  useEffect(() => {
    const diff = moment().diff(moment(localStorage.getItem('last_activity') || ''), "seconds")
    if (diff > (idleTimeout / 1000)) {
      setTimeoutCountdown(0)
      setTimeoutModalOpen(true)
      clearAllTime()
    }
  }, []);

  const handleLogoutUser = () => {
    dispatch(auth.actions.logout())
    localStorage.clear()
  }

  const clearSessionTimeout = () => {
    clearTimeout(timeout);
  };

  const clearSessionInterval = () => {
    clearInterval(countdownInterval);
  };

  const clearAllTime = () => {
    clearSessionInterval();
    clearSessionTimeout();
    localStorage.clear()
  };

  const handleContinue = () => {
    setTimeoutModalOpen(false);
    clearSessionInterval();
    clearSessionTimeout();
  };

  const onActive = () => {
    if (!timeoutModalOpen) {
      const timestorage = moment(localStorage.getItem('last_activity') || '')
      const diff = moment().diff(timestorage, "seconds")
      if (!timestorage.isValid() || (diff < ((idleTimeout + 5000) / 1000))) {
        localStorage.setItem('last_activity', (new Date()).toISOString())
      }

      clearSessionInterval();
      clearSessionTimeout();
    }
  };

  const onIdle = () => {
    const delay = 1000 * 5;
    if (authToken && !timeoutModalOpen) {
      timeout = setTimeout(() => {
        let countDown = 10;
        setTimeoutModalOpen(true);
        setTimeoutCountdown(countDown);
        countdownInterval = setInterval(() => {
          if (countDown > 0) {
            setTimeoutCountdown(--countDown);
          } else {
            clearAllTime()
          }
        }, 1000);
      }, delay);
    }
  };

  return (
    <>
      <IdleTimer
        ref={idleTimer}
        onActive={onActive}
        onIdle={onIdle}
        debounce={250}
        timeout={idleTimeout}
      />
      <TimeoutDialog
        countdown={timeoutCountdown}
        onContinue={handleContinue}
        onLogout={() => handleLogoutUser()}
        open={timeoutModalOpen}
      />
    </>
  );
}

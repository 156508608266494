import { Col, Input, Row, DatePicker } from 'antd';
import React from 'react';

const InputCustom = (props) => {
  const {
    label,
    value,
    onChange,
    keys,
    disabled,
    colType,
    passwordInput,
  } = props;

  const changeHandler = (e) => {
    onChange({ [keys]: e.target.value });
  };

  return (
    <Row gutter={14} style={{ marginBottom: '10px' }}>
      <Col span={colType === 'underlabel' ? 22 : 6}>
        <label>{label}</label>
      </Col>
      <Col span={colType === 'underlabel' ? 22 : 10}>
        {passwordInput === true ? (
          <Input.Password
            style={{ width: '100%' }}
            disabled={disabled}
            value={value}
            onChange={(e) => changeHandler(e)}
          />
        ) : (
          <Input
            style={{ width: '100%' }}
            disabled={disabled}
            value={value}
            onChange={(e) => changeHandler(e)}
          />
        )}
      </Col>
    </Row>
  );
};

export default InputCustom;

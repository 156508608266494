import { Card } from "antd";
import { Fragment } from "react";
import React from "react";
import ListItem from "./Form/ListItem";

const IndexItem = () => {
  return (
    <Fragment>
      <Card title="List Item">
        <ListItem />
      </Card>
    </Fragment>
  );
};

export default IndexItem;

import { Col, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { getReferensi } from "../API/AP_API";

const { Option } = Select;
const { REACT_APP_API } = process.env;

const DropdownFetch = (props) => {
  const {
    label,
    value,
    onChange,
    keys,
    disabled,
    nameValue,
    keyValue,
    url,
    changeDesc,
    descValue,
    colType,
  } = props;
  const [loading, setLoading] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [data, setData] = useState([]);
  const [parameter, setParameter] = useState("");
  const [nama, setNama] = useState("a") 
  useEffect(() => {
    const getApi = async () => {
      setLoading(true);
      try {
        const { data } = await getReferensi(`${REACT_APP_API}listdoctor?name=${nama}`);
        if (data.message === "Success") {
          const temp = data.data.rows || data.data || [];
          let dataMap = temp?.map((e) => {
            const id = e[keyValue].toString();
            const uraian = e[nameValue];
            const url = e.icon_url || "";
            const desc = e.description || "";
            return {
              id,
              uraian,
              url,
              desc,
            };
          });
          setData(dataMap);
          console.log({ dataMap, temp, value });
        }
      } catch (e) {
        console.warn("error get doctor", e);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };
    getApi();
  }, [trigger, nama]);

  const changeHandler = (e) => {
    if (descValue && e !== undefined) {
      const filter = data.filter((v) => {
        return v.uraian === e;
      });
      const temp = {
        desc: filter?.[0]?.desc,
        url: filter?.[0]?.url,
        type: filter?.[0].uraian,
      };
      changeDesc(temp);
    } else {
      onChange({ [keys]: e });
    }
  };

  // const handleSearch = (e) => {
  //   const param = `&${nameValue}=${e}`;
  //   setParameter(param);
  // };

  return (
    <Row gutter={14} style={{ marginBottom: "10px" }}>
      <Col span={colType === "underlabel" ? 22 : 6}>
        <label>{label}</label>
      </Col>
      <Col span={colType === "underlabel" ? 22 : 10}>
        <Select
          allowClear
          showSearch
          disabled={disabled}
          onFocus={() => setTrigger(!trigger)}
          style={{ width: "100%" }}
          onChange={(e) => changeHandler(e)}
          value={value?.toString()}
          defaultValue={value?.toString()}
          onSearch={(e) => setNama(e)}
          optionFilterProp="children"
          filterOption={false}
        >
          {data.map((e) => (
            <Option value={e.id}>{e.uraian}</Option>
          ))}
        </Select>
      </Col>
    </Row>
  );
};

export default DropdownFetch;

import React, { useEffect, useState } from "react";
import {
    Button,
    Modal,
    Row,
    Col,
    Select,
    message
} from "antd"
import { 
    MinusOutlined
} from "@ant-design/icons";
import {
  editSchedule,
  postSchedule
} from '../API/AP_API'
import moment from "moment"
import TimePickerComponent from "./TimePicker";
const { Option } = Select;

const ModalFormAddComponent = (props) => {
  const [tipe, setTipe] = useState(null);
  const [tipeLayanan, setTipeLayanan] = useState(null);
  const [day, setDay] = useState(null);
  const [time, setTime] = useState([{
    startTime: null,
    endTime: null
  }]);

  const { dataDoctor, userId, openModal, fromChildren, tipeId, tipeLayananId, dayId, timeId, conditionalRendering, scheduleId, isPartner } = props;

  useEffect(() => {
    setTipe(tipeId)
    setTipeLayanan(tipeLayananId)
    setDay(dayId)
    setTime(timeId)
  }, [tipeId, tipeLayananId, dayId, timeId])

  const layananFunc = (val) => {
    let v = String(val);
    const db = {
      "Telechat": "1",
      "TeleVcall": "2",
      "TeleVisit": "3",
      "TeleVaksinLab": "4",
      "TeleMeds": "5"
    };
    return db[v] || "Tidak Terdefinisi";
  };

  const typeLayananFunc = (val) => {
    let v = String(val);
    const db = {
      "Jadwal Praktek": "main",
      "Jadwal Konsultasi": "secondary",
    };
    return db[v] || "Tidak Terdefinisi";
  };

  const handleCancel = () => {
    setTipe(null);
    setTipeLayanan(null);
    setDay(null);
    setTime([{
        startTime: null,
        endTime: null
    }])
    fromChildren(false, true)
  };

  const handleOk = () => {
    if (day === null) {
      message.warning("Silahkan masukan pilihan hari")
    }
    if (tipeLayanan === null) {
      message.warning("Silahkan masukan tipe layanan")
    }
    if (tipe === null) {
      message.warning("Silahkan masukan tipe")
    }
    if (time[0].startTime === null || time[0].endTime === null) {
      message.warning("Silahkan masukan jadwal waktu")
    }
    if(conditionalRendering) {
      const inputUser = {
        "day": day,
        "start_time": time[0].startTime,
        "end_time": time[0].endTime,
        "type": typeLayananFunc(tipeLayanan) || tipeLayanan,
        "service_id": Number(layananFunc(tipe) || tipe)
      }
      requestApiUpdate(scheduleId, inputUser)
      handleCancel()
    }else {
      if (tipeLayanan !== null && day !== null && tipe !== null) {
        if (time[time.length - 1].startTime === null && time[time.length - 1].endTime === null) {
          time.pop()
          isPartner === true ? 
          time.forEach(el => {
            let tampStartTime = el.startTime
            let tampEndTime = el.endTime

            let inputUser = {
              "partner_id": Number(dataDoctor),
              "day": day,
              "start_time": tampStartTime,
              "end_time": tampEndTime,
              "type": tipeLayanan,
              "service_id": Number(tipe),
              "updated_by": userId
            }
            // console.log(inputUser, "==> PARTNER");
            requestApi(inputUser);
          }) :
          time.forEach(el => {
            let tampStartTime = el.startTime
            let tampEndTime = el.endTime

            let inputUser = {
              "doctor_id": Number(dataDoctor),
              "day": day,
              "start_time": tampStartTime,
              "end_time": tampEndTime,
              "type": tipeLayanan,
              "service_id": Number(tipe),
              "updated_by": userId
            }
            // console.log(inputUser, "==> DOCTOR");
            requestApi(inputUser);
          })
          handleCancel()
        }
      }
    }     
  }

  const handleChangeInputDay = (valueInput) => {
    setDay(valueInput)
  }

  const handleOnChangeTimeStart = (index, timeString) => {
    setTime([...time.slice(0, index),
      {
        startTime: timeString,
        endTime: time[index].endTime
      },
      ...time.slice(index + 1, time.length)
    ])
  }
    
  const handleOnChangeTimeFinish = (index, timeString) => {   
    setTime([
      ...time.slice(0, index),
      {
        startTime: time[index].startTime,
        endTime: timeString
      },
      ...time.slice(index + 1, time.length)
    ])
  }
    
  const addSchedule = () => {
    setTime([...time, 
      {
        startTime: null, 
        endTime: null
      }
    ])
  }

  useEffect(() => { 
    if (time[0].startTime || time[0].endTime) {
      addSchedule()
    }
  }, [time[0].endTime])

  const requestApi = async (inputUser) => {
    await postSchedule(inputUser)
    message.success("Data berhasil ditambahkan")
  }

  const requestApiUpdate = async(scheduleId, inputUser) => {
    await editSchedule(inputUser, scheduleId)
    message.success("Data berhasil diedit")
  }

  const removeSchedule = (index) => {
    setTime([...time.filter((_, id) => id !== index)]);
  }

  return (
      <>
        <Modal 
          title="Jadwal Praktek" 
          visible={openModal} 
          onCancel={handleCancel}
          onOk={handleOk}
          cancelButtonProps={{ style: { marginRight: "340px" } }}
          closable={false}
        >
          <p>Pilih Layanan</p>
          <Row>
            <Col span={6} offset={0}>
              {
                conditionalRendering !== true ?
                  isPartner === true ?
                    <Select
                      style={{ width: 140 }} 
                      onChange={e => setTipe(e)}
                      placeholder="Pilih Tipe"
                      value={tipe}
                    >
                      <Option value="4">TeleVaksinLab</Option>
                      <Option value="5">TeleMeds</Option>
                    </Select> : 
                    <Select
                      style={{ width: 140 }} 
                      onChange={e => setTipe(e)}
                      placeholder="Pilih Tipe"
                      value={tipe}
                      >
                      <Option value="1">TeleChat</Option>
                      <Option value="2">TeleVcall</Option>
                      <Option value="3">TeleVisit</Option>
                    </Select> 
                  :
                  <Select
                    style={{ width: 140 }} 
                    onChange={e => setTipe(e)}
                    placeholder="Pilih Tipe"
                    value={tipe}
                    disabled
                  >
                  </Select>
              }
            </Col>
            <Col span={6} offset={2}>
              { conditionalRendering !== true ? 
                <Select 
                  placeholder="Pilih Tipe Layanan"
                  style={{ width: 190 }} 
                  onChange={e => setTipeLayanan(e)}
                  value={tipeLayanan}           
                >
                  <Option value="main">Jadwal Praktek</Option>
                  <Option value="secondary">Jadwal Konsultasi</Option>
                </Select> :
                <Select 
                  placeholder="Pilih Tipe Layanan"
                  style={{ width: 190 }} 
                  onChange={e => setTipeLayanan(e)}
                  value={tipeLayanan}
                  disabled      
                >
                </Select>
              }    
              </Col>
            </Row>
            <br/>
            <p>Hari</p>
            {
              conditionalRendering !== true ?
              <Select 
                placeholder="Pilih hari..."
                value={day}
                style={{ width: 140 }} 
                onChange={handleChangeInputDay}
              >
                <Option value="monday">Senin</Option>
                <Option value="tuesday">Selasa</Option>
                <Option value="wednesday">Rabu</Option>
                <Option value="thursday">Kamis</Option>
                <Option value="friday">Jumaat</Option>
                <Option value="saturday">Sabtu</Option>
                <Option value="sunday">Minggu</Option>
              </Select> :
              <Select 
                placeholder="Pilih hari..."
                value={day}
                style={{ width: 140 }} 
                onChange={handleChangeInputDay}
                disabled
              >
                <Option value="monday">Senin</Option>
                <Option value="tuesday">Selasa</Option>
                <Option value="wednesday">Rabu</Option>
                <Option value="thursday">Kamis</Option>
                <Option value="friday">Jumaat</Option>
                <Option value="saturday">Sabtu</Option>
                <Option value="sunday">Minggu</Option>
              </Select>
            }
            <br/>
            <br/>
            <Row>
              <Col span={6} offset={0}>
                <p>Waktu Mulai</p>
              </Col>
              <Col span={6} offset={2}>
                <p>Waktu Selesai</p>
              </Col>
            </Row>
            {
              time.map((el, index) => 
                <>
                  <Row key={index}>
                    <Col span={6} offset={0}>
                      {/* {JSON.stringify(el.startTime)} */}
                      <TimePickerComponent
                        onChange={handleOnChangeTimeStart} 
                        index={index} 
                        value={el.startTime !== null && moment(el.startTime, 'HH:mm:ss') || time[index].startTime !== null && moment(time[index].startTime, 'HH:mm:ss')}
                      />
                    </Col>
                    <Col span={6} offset={2}>
                    {/* {JSON.stringify(el.endTime)} */}
                      <TimePickerComponent
                        onChange={handleOnChangeTimeFinish} 
                        index={index}
                        value={el.endTime !== null && moment(el.endTime, 'HH:mm:ss') || time[index].endTime !== null && moment(time[index].endTime, 'HH:mm:ss')}
                      />
                    </Col>
                    <Col span={6} offset={2}>
                      {
                        conditionalRendering !== true ? index === time.length -1 ?
                          <Button 
                            type="primary" 
                            shape="circle" 
                            onClick={addSchedule}
                          >
                            +
                          </Button> : 
                          <Button 
                            icon={<MinusOutlined />}
                            type="primary" 
                            shape="circle" 
                            onClick={() => removeSchedule(index)}
                            danger>
                          </Button> 
                      : '' }
                    </Col>
                  </Row>
                  <br/>
                </>
              )
            }              
          </Modal>
      </>
  )

  /** DEVELOPED WITH 💖 By Febrian Aditya */
}

export default ModalFormAddComponent

import { Col, Input, Row, Select } from 'antd'
import React from 'react'
import { typeService } from '../../../../utils/Data/DataDropdown';

const { Option } = Select;

const SelectServicePrice = (props) => {
    const { label, value, onChange, keys } = props;
    const children = typeService

    const changeHandlerTipe = (e) => {
        onChange({ [keys[0]]: e })
    }

    const changeHandlerDokter = (e) => {
        onChange({ [keys[1]]: e })
    }
    return (
        <>
            <Row gutter={14} style={{ marginBottom: '10px' }}>
                <Col span={6}>
                    <label>{label}</label>
                </Col>
                <Col span={10}>
                    <Row style={{ marginBottom: '10px' }}>
                        <Select
                            allowClear
                            style={{ width: '100%' }}
                            onChange={(e) => changeHandlerTipe(e)}
                            onSelect={(e) => (e)}
                            defaultValue={value}
                        >
                            {children.map((e) => (
                                <Option value={e.id}>{e.uraian}</Option>
                            ))}
                        </Select>
                    </Row>
                    {/* <Row>
                        <Select 
                            allowClear
                            style={{ width: '100%' }} 
                            onChange={(e) => changeHandlerDokter(e)} 
                            value={value.spesialis}
                        >
                            {children2.map((e) => (
                                <Option value={e.uraian}>{e.uraian}</Option>
                            ))}
                        </Select>
                    </Row> */}
                </Col>
            </Row>
        </>
    )
}

export default SelectServicePrice

import { Card } from "antd";
import { Fragment } from "react";
import React from "react";
import ListServicePrice from "./Form/ListServicePrice";

const ServicePrice = () => {
  return (
    <Fragment>
      <Card title="Service Price">
        <ListServicePrice />
      </Card>
    </Fragment>
  );
};

export default ServicePrice;

import { delete_request, get, post, put } from '../../utils/HttpRequest';
const { REACT_APP_API } = process.env;

//=============================================== DASHBOARD =========================================================================
export const getDashboardBody = async (type, status, firstDate, endDate) => {
  return await get({
    url: `${REACT_APP_API}dashboard/body?show=${type}&status=${status}&date1=${firstDate}&date2=${endDate}`,
  });
};

export const getDashboardHead = async (type, firstDate, endDate) => {
  const showDate = `&date1=${firstDate}&date2=${endDate}`;
  return await get({
    url: `${REACT_APP_API}dashboard/head?show=${type}${showDate}`,
  });
};

export const getDetilDashboard = async (
  type,
  status,
  firstDate,
  endDate,
  offsite,
  limit,
  params
) => {
  const keySearch = typeof params === 'undefined' ? '' : params;
  const tipe = type === 'head' ? '/head?' : `/body?show=${type}&`;
  const statuses = type === 'head' ? `show=${status}` : `status=${status}`;
  const api = `${REACT_APP_API}detail${tipe}date1=${firstDate}&date2=${endDate}&${statuses}&offset=${offsite}&limit=${limit}${keySearch}`;
  const apidokter = `${REACT_APP_API}${status}?page=${offsite}&limit=${limit}${keySearch}`;
  const apipartner = `${REACT_APP_API}${status}?offset=${offsite}&limit=${limit}${keySearch}`;
  return await get({
    url: status === 'listpartner' ? apipartner :  status === 'listdoctor' ? apidokter : api,
  });
  // return await get({url : `${REACT_APP_API}detail?limit=10&date1=all&date2=all&show=costumer`})
};

export const getItemsOrder = async (id) => {
  return await get({ url: `${REACT_APP_API}orderitemlist/${id}` });
};

export const getSummary = async (
  doctor_id,
  start_date,
  end_date,
  type_user_summary
) => {
  return await get({
    url: `${REACT_APP_API}summary/${type_user_summary}/${doctor_id}`,
    config: {
      params: {
        start_date,
        end_date,
      },
    },
  });
};

export const getListSummary = async (
  doctor_id,
  service_name,
  start_date,
  end_date,
  offset,
  limit,
  type_user_list
) => {
  // https://backend.okedok.co.id/office/summary/doctororderlist/39?service_name=okevcall&start_date=2021-06-14&end_date=2021-07-08&offset=0&limit=1000
  // return await get({ url: `${REACT_APP_API}summary/doctororderlist/${doctor_id}?service_name=${service_name}&start_date=${start_date}&end_date=${end_date}&offset=${offset}&limit=${limit}`,
  return await get({
    url: `${REACT_APP_API}summary/${type_user_list}/${doctor_id}`,
    config: {
      params: {
        service_name,
        start_date,
        end_date,
        offset,
        limit,
      },
    },
  });
};

export const importHistoryChat = async (order_id, order_code) => {
  return await post({
    url: `${REACT_APP_API}chats/import`,
    data: { order_id, order_code },
  });
};

export const getHistoryChat = async (order_id, order_code) => {
  return await get({
    url: `${REACT_APP_API}chats?order_id=${order_id}&order_code=${order_code}`,
  });
};

export const REGISTER_USER = async (body) => {
  return await post({ url: `${REACT_APP_API}register`, data: body });
};

export const VERIFY_USER = async (body) => {
  return await post({ url: `${REACT_APP_API}verifytoken`, data: body });
};

// ===================================================== DOCTOR =======================================================================

export const getListDoctor = async (pagination, parameter) => {
  return await get({
    url: `${REACT_APP_API}listdoctor${pagination ? pagination : ''}${
      parameter ? parameter : ''
    }`,
  });
};

export const putDoctor = async (body) => {
  return await put({ url: `${REACT_APP_API}doctors/${body.id}`, data: body });
};

export const putStatus = async (body, param, id) => {
  return await post({
    url: `${REACT_APP_API}doctors/${id}/${param}`,
    data: body,
  });
};
export const countDoctor = async () => {
  return await get({
    url: `${REACT_APP_API}doctors/count`,
  });
};

// ===================================================== PARTNERS  =======================================================================
export const countPartner = async () => {
  return await get({
    url: `${REACT_APP_API}partners/count`,
  });
};
export const getListPartner = async (pagination, parameter) => {
  return await get({
    url: `${REACT_APP_API}partners${pagination ? pagination : ''}${
      parameter ? parameter : ''
    }`,
  });
};

export const putPartner = async (body) => {
  return await put({ url: `${REACT_APP_API}partners/${body.id}`, data: body });
};

export const putStatusPartner = async (body, param, id) => {
  return await post({
    url: `${REACT_APP_API}partners/${id}/${param}`,
    data: body,
  });
};

// ===================================================== LICENSES =======================================================================

export const getListLisensi = async (id) => {
  return await get({ url: `${REACT_APP_API}doctorlicenses?doctor_id=${id}` });
};

export const getLisensiPartner = async (id) => {
  return await get({ url: `${REACT_APP_API}/partners/${id}` });
};

export const postLisensi = async (body) => {
  return await post({ url: `${REACT_APP_API}doctorlicenses`, data: body });
};

export const editLisensi = async (body, id) => {
  return await put({ url: `${REACT_APP_API}doctorlicenses/${id}`, data: body });
};

export const deleteLisensi = async (id) => {
  return await delete_request({ url: `${REACT_APP_API}doctorlicenses/${id}` });
};

// ===================================================== SCHEDULE =======================================================================

export const getSchedule = async (pagination, parameter) => {
  let type = 'doctor';
  return await get({
    url: `${REACT_APP_API}schedules${pagination ? pagination : ''}${
      parameter ? parameter : ''
    }`,
  });
};

export const getTotalSchedule = async () => {
  let type = 'doctor';
  return await get({
    url: `${REACT_APP_API}schedules/count?user_type=${type}`,
  });
};

export const postSchedule = async (body) => {
  // console.log(body, "==> MASUK API");
  return await post({ url: `${REACT_APP_API}schedules`, data: body });
};

export const editSchedule = async (body, id) => {
  return await put({
    url: `${REACT_APP_API}schedules/${id}`,
    data: body,
  });
};

export const deleteSchedule = async (id) => {
  // console.log(id, "==> API");
  return await delete_request({ url: `${REACT_APP_API}schedules/${id}` });
};

// ===================================================== AVAILABILITIES =======================================================================

export const getService = async (id) => {
  return await get({
    url: `${REACT_APP_API}doctoravailabilities?doctor_id=${id}`,
  });
};

export const getAllService = async () => {
  return await get({ url: `${REACT_APP_API}services` });
};

export const editAvailable = async (body, id) => {
  return await put({
    url: `${REACT_APP_API}doctoravailabilities/${id}`,
    data: body,
  });
};

export const postAvailable = async (body) => {
  return await post({
    url: `${REACT_APP_API}doctoravailabilities`,
    data: body,
  });
};

// ===================================================== QUEUE =======================================================================
export const getQueues = async (pagination, parameter) => {
  return await get({
    url: `${REACT_APP_API}queues${pagination ? pagination : ''}${
      parameter ? parameter : ''
    }`,
  });
};

export const saveEditQueue = async (body) => {
  console.log('asdasd', body);
  return await put({
    url: `${REACT_APP_API}queues/${body.idQueue.id}`,
    data: body.sendDataQueue,
  });
};

export const deleteQueue = async (id) => {
  return await delete_request({ url: `${REACT_APP_API}queues/${id}` });
};

export const addQueue = async (body) => {
  return await post({
    url: `${REACT_APP_API}queues`,
    data: body.sendDataQueue,
  });
};

export const getTotalQueue = async () => {
  return await get({
    url: `${REACT_APP_API}queues/count`,
  });
};

// ===================================================== ARTICLES =======================================================================

export const getArticles = async (pagination, parameter) => {
  return await get({
    url: `${REACT_APP_API}articles${pagination ? pagination : ''}${
      parameter ? parameter : ''
    }`,
  });
};

export const deleteArticle = async (id) => {
  return await delete_request({ url: `${REACT_APP_API}articles/${id}` });
};

export const putArticle = async (body) => {
  const id = body.idArticle.id;
  return await put({
    url: `${REACT_APP_API}articles/${id}`,
    data: body.sendDataArticles,
  });
};

export const addArticle = async (body) => {
  return await post({
    url: `${REACT_APP_API}articles`,
    data: body.sendDataArticles,
  });
};

export const getTotalArticle = async () => {
  return await get({
    url: `${REACT_APP_API}articles/count`,
  });
};

// ===================================================== ITEMS =======================================================================
export const getSettings = async (pagination, parameter) => {
  return await get({
    url: `${REACT_APP_API}settings${pagination ? pagination : ""}${parameter ? parameter : ""
      }`,
  });
};
export const getTotalSettings = async () => {
  return await get({
    url: `${REACT_APP_API}settings/count`,
  });
};
export const deteleSetting = async (id) => {
  return await delete_request({ url: `${REACT_APP_API}settings/${id}` });
};
export const putSettings = async (body, id) => {
  return await put({
    url: `${REACT_APP_API}settings/${id}`,
    data: body,
  });
};
export const addSettings = async (body) => {
  return await post({
    url: `${REACT_APP_API}settings`,
    data: body[0],
  });
};
// ===================================================== ITEMS =======================================================================
export const getItem = async (pagination, parameter) => {
  return await get({
    url: `${REACT_APP_API}items${pagination ? pagination : ''}${
      parameter ? parameter : ''
    }`,
  });
};
export const getTotalItems = async () => {
  return await get({
    url: `${REACT_APP_API}items/count`,
  });
};
export const deleteItem = async (id) => {
  return await delete_request({ url: `${REACT_APP_API}items/${id}` });
};
export const putItem = async (body, id) => {
  return await put({
    url: `${REACT_APP_API}items/${id}`,
    data: body,
  });
};
export const addItem = async (body) => {
  return await post({
    url: `${REACT_APP_API}items`,
    data: body[0],
  });
};
// ===================================================== SERVICE PRICE =======================================================================
export const getServicePrice = async (pagination, parameter) => {
  return await get({
    url: `${REACT_APP_API}serviceprices${pagination ? pagination : ''}${
      parameter ? parameter : ''
    }`,
  });
};
export const getTotalSercicePrice = async () => {
  return await get({
    url: `${REACT_APP_API}serviceprices/count`,
  });
};
export const addPrice = async (body) => {
  return await post({
    url: `${REACT_APP_API}serviceprices`,
    data: body[0],
  });
};
export const putPrice = async (body, id) => {
  return await put({
    url: `${REACT_APP_API}serviceprices/${id}`,
    data: body,
  });
};
export const deletePrice = async (id) => {
  return await delete_request({
    url: `${REACT_APP_API}serviceprices/${id}`,
  });
};
// ===================================================== TIPE DOKTER =======================================================================

export const getType = async (pagination, parameter) => {
  return await get({
    url: `${REACT_APP_API}doctortypes${pagination ? pagination : ''}${
      parameter ? parameter : ''
    }`,
  });
};

export const addType = async (body) => {
  return await post({
    url: `${REACT_APP_API}doctortypes`,
    data: body,
  });
};

export const putType = async (body, id) => {
  return await put({
    url: `${REACT_APP_API}doctortypes/${id}`,
    data: body,
  });
};

export const deleteType = async (id) => {
  return await delete_request({
    url: `${REACT_APP_API}doctortypes/${id}`,
  });
};

export const getTotalType = async () => {
  return await get({
    url: `${REACT_APP_API}doctortypes/count`,
  });
};

// ===================================================== REFERENSI =======================================================================

export const getReferensi = async (url) => {
  return await get({
    url: url,
  });
};

// ================================================= USER MANAGEMENT ===================================================

export const getListUsers = async (parameter) => {
  return await get({
    url: `${REACT_APP_API}users/?${parameter ? parameter : ''}`,
  });
};

export const addUser = async (body) => {
  return await post({
    url: `${REACT_APP_API}users`,
    data: body,
  });
};

export const putUser = async (data) => {
  const id = data.param;
  return await put({
    url: `${REACT_APP_API}users/${id}`,
    data: data.body,
  });
};
// export const deleteArticle = async (id) => {
//   return await delete_request({ url: `${REACT_APP_API}articles/${id}` });
// };

// export const getTotalArticle = async () => {
//   return await get({
//     url: `${REACT_APP_API}articles/count`,
//   });
// };

import { Col, Row, Image } from "antd";
import GoogleMapReact from "google-map-react";
import React, {useEffect, useState} from "react";
import ReactGoogleAutocomplete from "react-google-autocomplete";
const { REACT_APP_MAP_API_KEY } = process.env;
const AnyReactComponent = ({ text }) => (
  <div>
    <Image
      width={30}
      src="https://thumbs.gfycat.com/InexperiencedGlossyAsiaticgreaterfreshwaterclam-size_restricted.gif"
    />
  </div>
);

const MapsCustom = (props) => {
  const { label, value, onChange, keys } = props;
  const [center, setCenter] = useState({})
  const zoom = 16;
  // const center = {
  //   lat: value?.x || -6.205135,
  //   lng: value?.y || 106.874022,
  // };

  useEffect(() => {
    const getCurrentLocation = async () => {
      await navigator.geolocation.getCurrentPosition(
        position => {
        console.log({position})
        if (!value) {
          const temp = {
            lat: position.coords.latitude, 
            lng: position.coords.longitude
          }
          setCenter(temp)
        } else {
          const temp2 = {
              lat: value?.x,
              lng: value?.y,
            };
          setCenter(temp2)
        }
        // err => console.log(err)
        });
    };
    getCurrentLocation();
  }, []);

  return (
    <Row gutter={14} style={{ marginBottom: "10px" }}>
      <Col span={22}>
        <label>{label}</label>
      </Col>
      <Col span={22} style={{ width: 400, height: 250 }}>
        <ReactGoogleAutocomplete
        placeholder="Cari Lokasi"
        style={{ width: "100%" }}
        apiKey={REACT_APP_MAP_API_KEY}
        options={{
          types: ["geocode"],
          componentRestrictions: { country: "id" },
              }}
  onPlaceSelected={(e) => {
    setCenter({
      lat: e.geometry.location.lat(),
      lng: e.geometry.location.lng()
    })
  }}
/>
         <br />
        <GoogleMapReact
          bootstrapURLKeys={{ key: REACT_APP_MAP_API_KEY}}
          defaultCenter={center}
          center={center}
          defaultZoom={zoom}
          onChange={(e) => {
            const position = {
              x: e.center.lat,
              y: e.center.lng,
            };
            onChange({ [keys]: position });
          }}
          onClick={(e) => {
            const position = {
              x: e.lat,
              y: e.lng,
            };
            onChange({ [keys]: position });
          }}
        >
          <AnyReactComponent lat={value?.x} lng={value?.y} text="Lokasi" />
        </GoogleMapReact>
      </Col>
    </Row>
  );
};

export default MapsCustom;

import { Col, DatePicker, Input, Row } from "antd";
import moment from "moment";
import React from "react";

const InputPicker = (props) => {
  const { label, value, onChange, keys, disabled, colType } = props;

  const changeHandlerTempat = (e) => {
    onChange({ [keys[0]]: e.target.value });
  };

  return (
    <Row gutter={14} style={{ marginBottom: "10px" }}>
      <Col span={colType === "underlabel" ? 22 : 6}>
        <label>{label}</label>
      </Col>
      <Col span={colType === "underlabel" ? 11 : 5}>
        <Input
          style={{ width: "100%" }}
          disabled={disabled}
          value={value[keys[0]]}
          onChange={(e) => changeHandlerTempat(e)}
        />
      </Col>
      <Col span={colType === "underlabel" ? 11 : 6}>
        <DatePicker
          format={"DD-MM-YYYY"}
          disabled={disabled}
          style={{ width: "100%" }}
          value={
            value[keys[1]]
              ? moment(value[keys[1]], "DD-MM-YYYY")
              : undefined
          }
          maxLength={50}
          onChange={(e, date) => {
            const temp = moment(e, "DD-MM-YYYY");
            onChange({ [keys[1]]: temp });
          }}
        />
      </Col>
    </Row>
  );
};

export default InputPicker;

import {lazy} from "react";
import IndexItem from "./Items/IndexItem";
import IndexSetting from "./Settings/IndexSetting";
import IndexServicePrice from "./ServicePrice/IndexServicePrice";
import IndexRegistration2 from "./Partners/Registration/IndexRegistration";
import IndexPartner from "./Partners/Partner/IndexPartner";

const DashboardPage = lazy(() =>
  import("./Dashboard/index"));
const DashboardDetail = lazy(() =>
  import("./Dashboard/DashboardDetail"));
const DetailSummary = lazy(() =>
  import("./Dashboard/DetailSummary"));
const Users = lazy(() =>
  import("./User&Role/Users"));
const IndexDoctor = lazy(() =>
  import("./Doctors/Doctor/IndexDoctor"));
const IndexQueue = lazy(() =>
  import("./Doctors/Queue/IndexQueue"));
const IndexRegistration = lazy(() =>
  import("./Doctors/Registration/IndexRegistration"));
const IndexSchedule = lazy(() =>
  import("./Doctors/Schedule/IndexSchedule"));
const IndexArticle = lazy(() =>
  import("./Articles/Article/IndexArticle"));
const IndexTipe = lazy(() =>
  import("./TipeDokter/IndexTipe"));


const PAGES = [
  {
    path: '/dashboard', component: DashboardPage
  },{
    path: '/detail-dashboard/:type/:id/:date1/:date2/:showDate', component: DashboardDetail
  },{
    path: '/summary-dashboard/:type/:user_or_doctor_id/:title', component: DetailSummary
  },{
    path: '/users', component: Users
  },{
    path: '/articles/article', component: IndexArticle
  },{
    path: '/tipe/tipedokter', component: IndexTipe
  },{
    path: '/doctors/doctor', component: IndexDoctor
  },{
    path: '/partners/partner', component: IndexPartner
  },{
    path: '/doctors/queue', component: IndexQueue
  },{
    path: '/doctors/registration', component: IndexRegistration
  },{
    path: '/doctors/schedule', component: IndexSchedule
  },{
    path: '/partners/registration', component: IndexRegistration2
  },{
    path: '/items/item', component: IndexItem
  }, {
    path: '/service/price', component: IndexServicePrice
  },{
    path: '/settings/setting', component: IndexSetting
  },
  
]

export default PAGES

import { Col, Image, Row, Select } from "antd";
import React from "react";
import DropdownFetch from "../../../../Components/DropdownFetch";
const { REACT_APP_API } = process.env;

const { Option } = Select;

const TipeDokter = (props) => {
  const { label, value, onChange, keys, disabled, colType } = props;

  const changeHandlerTipe = (e) => {
    console.log("change", { e });
    onChange({ [keys[0]]: e.type, [keys[1]]: e.desc, [keys[2]]: e.url });
  };

  return (
    <>
      <DropdownFetch
        label={label}
        url={`${REACT_APP_API}doctortypes`}
        value={value.partner_type}
        onChange={onChange}
        changeDesc={(e) => changeHandlerTipe(e)}
        keys={"partner_type"}
        disabled={disabled}
        nameValue={"name"}
        keyValue={"name"}
        descValue={true}
        colType={colType}
      />
    </>
  );
};

export default TipeDokter;

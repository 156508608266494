import axios from 'axios';
import { post } from '../../../../utils/HttpRequest';
import jwt_decode from 'jwt-decode';
import { isEmpty } from 'lodash';
export const LOGIN_URL = `${process.env.REACT_APP_API_URL}/auth/login`;
export const REQUEST_PASSWORD_URL = 'api/auth/forgot-password';
export const ME_URL = `${process.env.REACT_APP_API_URL}/auth/me`;
const { REACT_APP_API } = process.env;
export const REGISTER_URL = `${REACT_APP_API}auth/register`;
export const AUTHENTICATE_URL = `${REACT_APP_API}auth/authenticate`;
export const AUTHENTICATOR_URL = `${REACT_APP_API}auth/authenticator`;
export function login(email, password) {
  const body = {
    email: email,
    password: password,
  };
  return post({ url: `${REACT_APP_API}auth/login`, data: body });
}

export function register(email, fullname, username, password) {
  const body = {
    name: username,
    email: email,
    password: password,
    phone: fullname,
  };
  return post({ url: `${REACT_APP_API}auth/register`, data: body });
}

export function authenticator() {
  return post({
    url: AUTHENTICATOR_URL,
  });
}

export function authenticate(data) {
  const body = {
    authenticator_code: data,
  };
  return post({
    url: AUTHENTICATE_URL,
    data: body,
  });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  const token = localStorage.getItem('token');
  const isAuthenticated = localStorage.getItem('isAuthenticated');

  const decode = !isEmpty(token) ? jwt_decode(token) : '';
  // Authorization head should be fulfilled in interceptor.

  // if without two-factor-authentication uncomment this
  // const data = { data: decode };

  // iff using two-factor authentication
  const data = !isEmpty(isAuthenticated) ? { data: decode } : '';

  return data;
}

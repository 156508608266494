import {
  Button,
  Col,
  Image,
  Input,
  message,
  Row,
  Select,
  Spin,
  Upload,
} from "antd";
import React, { useState } from "react";
import { UploadOutlined } from "@ant-design/icons";

const TipeDokter = (props) => {
  const { label, value, onChange, keys, colType } = props;
  const [loading, setLoading] = useState(false);
  const hiddenFileInput = React.useRef(null);

  const uploadFile = async (e) => {
    setLoading(true);
    const files = e.target.files;
    const data = new FormData();
    data.append("file", files[0]);
    data.append("upload_preset", "fnu8tvra");
    data.append("cloud_name", "rsud_kita");
    try {
      const res = await fetch(
        // "https://api.cloudinary.com/v1_1/okedok/image/upload",
        "https://api.cloudinary.com/v1_1/dwgxtp9rl/image/upload",
        {
          method: "POST",
          body: data,
        }
      );
      const file = await res.json();
      if (file.secure_url) {
        onChange({ [keys]: file.secure_url });
        message.success("Foto Berhasil Diperbaharui");
        setLoading(false);
      }
    } catch (e) {
      console.warn("error upload", e);
      message.error("Gagal Upload, Coba Lagi");
      setLoading(false);
    }
  };

  const onClick = () => {
    hiddenFileInput.current.click();
  };

  return (
    <>
      <Row gutter={14} style={{ marginBottom: "10px" }}>
        <Col span={colType === "underlabel" ? 20 : 6}>
          <label>{label}</label>
        </Col>
        <Col span={colType === "underlabel" ? 20 : 8}>
          <Row style={{ marginBottom: "10px" }}>
            {loading ? (
              <Spin />
            ) : (
              <Image
                width={200}
                src={
                  value
                    ? value
                    : "https://st2.depositphotos.com/1104517/11965/v/600/depositphotos_119659092-stock-illustration-male-avatar-profile-picture-vector.jpg"
                }
              />
            )}
          </Row>
          <Row>
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={uploadFile}
              style={{ display: "none" }}
            />
            <Button
              icon={<UploadOutlined />}
              loading={loading}
              onClick={onClick}
            >
              Select Image
            </Button>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default TipeDokter;

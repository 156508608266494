export const gender = [
  {
    id: "1",
    uraian: "male",
  },
  {
    id: "2",
    uraian: "female",
  },
];

export const agama = [
  {
    id: "1",
    uraian: "islam",
  },
  {
    id: "2",
    uraian: "confucianism",
  },
  {
    id: "3",
    uraian: "catholic",
  },
  {
    id: "4",
    uraian: "protestant",
  },
  {
    id: "5",
    uraian: "hindu",
  },
  {
    id: "6",
    uraian: "buddha",
  },
  {
    id: "7",
    uraian: "other",
  },
];

export const spesialis = [
  {
    id: "1",
    uraian: "Gigi",
  },
  {
    id: "2",
    uraian: "Jantung",
  },
  {
    id: "3",
    uraian: "THT",
  },
  {
    id: "4",
    uraian: "Dalam",
  },
];

export const level = [
  {
    id: 1,
    uraian: "Level 1",
  },
  {
    id: 2,
    uraian: "Level 2",
  },
  {
    id: 3,
    uraian: "Level 3",
  },
];

export const category = [
  {
    id: "1",
    uraian: "okeDok",
  },
  {
    id: "2",
    uraian: "okeChat",
  },
  {
    id: "3",
    uraian: "okeVcall",
  },
];

export const day = [
  {
    id: "1",
    uraian: "sunday",
  },
  {
    id: "2",
    uraian: "monday",
  },
  {
    id: "3",
    uraian: "tuesday",
  },
  {
    id: "4",
    uraian: "wednesday",
  },
  {
    id: "5",
    uraian: "thursday",
  },
  {
    id: "6",
    uraian: "friday",
  },
  {
    id: "7",
    uraian: "saturday",
  },
];

export const partner = [
  {
    id: 1,
    uraian: "RS Mitra",
  },
  {
    id: 2,
    uraian: "RS Cipto",
  },
  {
    id: 3,
    uraian: "RS Siloam",
  },
];

export const tipe = [
  {
    id: "1",
    uraian: "umum",
  },
  {
    id: "2",
    uraian: "vaksin",
  },
];

export const service = [
  {
    id: "1",
    uraian: "TeleChat",
  },
  {
    id: "2",
    uraian: "TeleVcall",
  },
  {
    id: "3",
    uraian: "Visit Dokter",
  },
  {
    id: "4",
    uraian: "TeleVaksin",
  },
];

export const typeArticle = [
  {
    id: 1,
    uraian: "banner",
  },
  {
    id: 2,
    uraian: "article",
  },
];
export const typeItem = [
  {
    id: 1,
    uraian: "vaccine",
  },
  {
    id: 2,
    uraian: "radiology",
  },
  {
    id: 3,
    uraian: "medicine",
  },
  {
    id: 4,
    uraian: "laboratory",
  },
];
export const typeService = [
  {
    id: 1,
    uraian: "TeleChat",
  },
  {
    id: 2,
    uraian: "TeleVcall",
  },
  {
    id: 3,
    uraian: "Visit Dokter",
  },
  {
    id: 4,
    uraian: "TeleVaksin",
  },
  {
    id: 5,
    uraian: "TeleMeds",
  },
];
export const shareFeeFor = [
  {
    id: 1,
    uraian: "renter",
  },
  {
    id: 2,
    uraian: "tenant",
  }
];
export const feeType = [
  {
    id: 1,
    uraian: "percent",
  },
  {
    id: 2,
    uraian: "nominal",
  },
];

export const statusPublish = [
  {
    id: true,
    uraian: "Published",
  },
  {
    id: false,
    uraian: "Archived",
  },
];

export const shareFee = [
  {
    id: "tenant",
    uraian: "Tenant",
  },
  {
    id: "renter",
    uraian: "Renter",
  },
];

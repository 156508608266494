import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import * as auth from '../_redux/authRedux';
import { authenticator, authenticate } from '../_redux/authCrud';
import { notification } from 'antd';
import jwt_decode from 'jwt-decode';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router';

const initialValues = {
  authenticator_code: '',
};
function Authentication(props) {
  const [loading, setLoading] = useState(false);
  const [qrImage, setQrImage] = useState('');
  const AuthSchema = Yup.object().shape({
    authenticator_code: Yup.string()
      .matches(/[0-9]+/gi, 'Enter number only')
      .length(6, 'Enter correct code')
      .required(`This field can't be empty`),
  });

  const history = useHistory();

  const enableLoading = () => {
    setLoading(true);
  };
  const disableLoading = () => {
    setLoading(false);
  };

  let data;
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!isEmpty(token)) {
      data = jwt_decode(token);
      if (!data.authenticator) {
        authenticator().then((result) => {
          setQrImage(result.data.data);
        });
      }
    } else {
      history.push('/auth/login');
    }
  }, []);

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }

    return '';
  };

  const formik = useFormik({
    initialValues,
    validationSchema: AuthSchema,
    onSubmit: (values, { setSubmitting }) => {
      enableLoading();
      setTimeout(async () => {
        try {
          const { data } = await authenticate(values.authenticator_code);
          if (data.status === 200) {
            await localStorage.setItem('isAuthenticated', true);
            setTimeout(async () => {
              let token = await localStorage.getItem('token');
              if (token) {
                props.login(token);
                disableLoading();
              }
            }, 500);
          } else if (data.status === 401) {
            notification.error({
              message:
                'Token auth telah kadaluarsa, silahkan gunakan token auth terbaru.',
            });
            disableLoading();
          }
        } catch (e) {
          notification.error({
            message:
              'Terjadi kesalahan saat login, periksa koneksi internet anda',
          });
        } finally {
          disableLoading();
          setSubmitting(false);
        }
      }, 1000);
    },
  });

  return (
    <div>
      <div className='text-center mb-10 mb-lg-10'>
        <h3 className='font-size-h1'>
          <h1>Two Factor Authentication</h1>
        </h3>
        <p className='text-muted font-weight-bold'>
          Enter the authentication code that was sent to your Google
          Authentication App
        </p>
      </div>
      {qrImage ? (
        <div className='card p-5 mb-10 text-center'>
          <p className=' text-muted font-weight-bold'>
            Scan this QR Code with your Google Authentication App
            <br /> and enter verivication code below.
          </p>
          <div className='text-center'>
            <img src={qrImage} />
          </div>
        </div>
      ) : null}

      <form
        onSubmit={formik.handleSubmit}
        className='form fv-plugins-bootstrap fv-plugins-framework'
      >
        <div className='form-group fv-plugins-icon-container'>
          <input
            placeholder='Google Authenticator'
            type='text'
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              'authenticator_code'
            )}`}
            name='authenticator_code'
            {...formik.getFieldProps('authenticator_code')}
          />
          {formik.touched.authenticator_code &&
          formik.errors.authenticator_code ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                {formik.errors.authenticator_code}
              </div>
            </div>
          ) : null}
        </div>
        <div className='form-group d-flex flex-wrap justify-content-between align-items-center'>
          <button
            type='submit'
            disabled={formik.isSubmitting}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
          >
            <span>Next</span>
            {loading && <span className='ml-3 spinner spinner-white'></span>}
          </button>
        </div>
      </form>
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Authentication));

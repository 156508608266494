import React, { useState } from 'react';
// import { Link } from "react-router-dom";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as auth from '../_redux/authRedux';
import { login } from '../_redux/authCrud';
import { notification } from 'antd';
import { useHistory } from 'react-router';

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  email: '',
  password: '',
  token: '',
};

function Login(props) {
  const history = useHistory();
  // const { intl } = props;
  const [loading, setLoading] = useState(false);
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required(),
    password: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required(),
    // token: Yup.string()
    //   .min(3, 'Minimum 3 symbols')
    //   .max(50, 'Maximum 50 symbols')
    //   .required(),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }

    return '';
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setSubmitting }) => {
      enableLoading();
      setTimeout(async () => {
        try {
          const { data } = await login(
            values.email,
            values.password
            // values.token
          );
          const token = data.data;

          if (data.status === 200) {
            await localStorage.setItem('token', token.jwt_token);
            setTimeout(async () => {
              let token = await localStorage.getItem('token');
              if (token) {
                props.login(token);
                disableLoading();
                history.push('/auth/two-factor-authentication');
              }
            }, 2000);
          } else if (data.status === 500) {
            notification.error({
              message:
                'Token auth telah kadaluarsa, silahkan gunakan token auth terbaru.',
            });
            disableLoading();
          }
        } catch (e) {
          notification.error({
            message:
              'Terjadi kesalahan saat login, periksa koneksi internet anda',
          });
        } finally {
          disableLoading();
          setSubmitting(false);
        }
      }, 1000);
    },
  });

  return (
    <div className='login-form login-signin' id='kt_login_signin_form'>
      {/* begin::Head */}
      <div className='text-center mb-10 mb-lg-20'>
        <h3 className='font-size-h1'>
          <FormattedMessage id='AUTH.LOGIN.TITLE' />
        </h3>
        <p className='text-muted font-weight-bold'>
          Enter your username and password
        </p>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className='form fv-plugins-bootstrap fv-plugins-framework'
      >
        <div className='form-group fv-plugins-icon-container'>
          <input
            placeholder='Email'
            type='email'
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              'email'
            )}`}
            name='email'
            {...formik.getFieldProps('email')}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        <div className='form-group fv-plugins-icon-container'>
          <input
            placeholder='Password'
            type='password'
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              'password'
            )}`}
            name='password'
            {...formik.getFieldProps('password')}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        {/* <div className='form-group fv-plugins-icon-container'>
          <input
            placeholder='Google Authenticator'
            type='text'
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              'token'
            )}`}
            name='token'
            {...formik.getFieldProps('token')}
          />
          {formik.touched.token && formik.errors.token ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{formik.errors.token}</div>
            </div>
          ) : null}
        </div> */}
        <div className='form-group d-flex flex-wrap justify-content-between align-items-center'>
          <button
            id='kt_login_signin_submit'
            type='submit'
            disabled={formik.isSubmitting}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
          >
            <span>Sign In</span>
            {loading && <span className='ml-3 spinner spinner-white'></span>}
          </button>
        </div>
      </form>
      {/*end::Form*/}
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
// export default Login;

import { Col, Input, Row, DatePicker, Switch } from 'antd';
import React from 'react';

const SwitchCustom = (props) => {
  const {
    label,
    value,
    onChange,
    keys,
    disabled,
    colType,
    passwordInput,
  } = props;

  const changeHandler = (e) => {
    onChange({ [keys]: e});
    //console.log(e)
    console.log("dsas", e)
  };
  console.log("dsa", value) 
  return (
    <Row gutter={14} style={{ marginBottom: '10px' }}>
      <Col span={colType === 'underlabel' ? 22 : 6}>
        <label>{label}</label>
      </Col>
      <Col span={colType === 'underlabel' ? 22 : 10}>
       <Switch defaultChecked={value} onChange={(e) => changeHandler(e)} />
      </Col>
    </Row>
  );
};

export default SwitchCustom;

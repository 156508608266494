import a from 'axios';
import dotenv from 'dotenv';

dotenv.config();
const { REACT_APP_API, REACT_APP_API_KEY, REACT_APP_STAGE } = process.env;

export const base_url = REACT_APP_API

const getAccessHeader = {
  headers : {
    [REACT_APP_STAGE === 'prod' ? 'x-api-key' : 'apikey'] : `${REACT_APP_API_KEY}`,
    "Content-type": "application/json",
  }
};

// if (REACT_APP_STAGE !== 'prod') {
//   getAccessHeader =  { headers : {
//     apikey : `${REACT_APP_API_KEY}`,
//   }};
// } else {
//   getAccessHeader =  { headers : {
//     "x-api-key" : `${REACT_APP_API_KEY}`,
//   }};
// }

const mergeRecursive = (obj1, obj2) => {
  if (!obj1) return obj2
  for (let p in obj2) {
    try {
      // Property in destination object set; update its value.
      if ( obj2[p].constructor===Object ) {
        obj1[p] = this.mergeRecursive(obj1[p], obj2[p]);

      } else {
        obj1[p] = obj2[p];

      }

    } catch(e) {
      // Property in destination object not set; create it and set its value.
      obj1[p] = obj2[p];
    }
  }
  return obj1;
}

export function get(resourceHttpRequest) {
  const { url, config } = resourceHttpRequest
  return a.get(url, mergeRecursive(config, getAccessHeader))
}

export function post(resourceHttpRequest) {
  const { url, config, data } = resourceHttpRequest
  return a.post(url, data, mergeRecursive(config, getAccessHeader))
}

export function put(resourceHttpRequest) {
  const { url, config, data } = resourceHttpRequest
  return a.patch(url, data, mergeRecursive(config, getAccessHeader))
}

export function delete_request(resourceHttpRequest) {
  const { url, config } = resourceHttpRequest
  return a.delete(url,  mergeRecursive(config, getAccessHeader))
}

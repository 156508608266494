import { Card } from "antd";
import { Fragment } from "react";
import React from "react";
import ListSetting from "./Form/ListSetting";

const IndexSetting = () => {
  return (
    <Fragment>
      <Card title="Settings">
       <ListSetting />
      </Card>
    </Fragment>
  );
};

export default IndexSetting;

import React, { useState } from "react";
import { gender, agama, partner } from "../../../../../utils/Data/DataDropdown";
import DropdownCustom from "../../../../Components/DropdownCustom";
import InputCustom from "../../../../Components/InputCustom";
import InputPicker from "../../../../Components/InputPicker";
import TextAreaCustom from "../../../../Components/TextAreaCustom";
import ImageUpload from "../../../../Components/ImageUpload";
import TipeDokter from "./TipeDokter";
import { Button, Image, Row, Col } from "antd";
import MapsCustom from "../../../../Components/MapsCustom";
import DatePickerCustom from "../../../../Components/DatePickerCustom";
import SearchLocationInput from "../../../../Components/SearchLocationInput";
import SwitchCustom from "../../../../Components/SwitchCustom";

const Profile = (props) => {
  const { data, onChange, back, save, loading, disabled } = props;

  return (
    <div>
      <h3>Data Identitas Diri Partner</h3>
      <hr />
      <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
        <div style={{ width: "100%" }}>
          <InputCustom
            label={"ID Partner"}
            value={data.id}
            onChange={onChange}
            keys={"id"}
            disabled={true}
            colType={"underlabel"}
          />
          <SwitchCustom label={"Available"} keys={"is_available"} value={data.is_available} onChange={onChange}/>
          <InputCustom
            label={"Nama Penyedia Layanan"}
            value={data.name}
            onChange={onChange}
            keys={"name"}
            disabled={disabled}
            colType={"underlabel"}
          />
          <InputCustom
            label={"No Hp / Hotline"}
            value={data.phone}
            onChange={onChange}
            keys={"phone"}
            disabled={disabled}
            colType={"underlabel"}
          />
          <InputCustom
            label={"Email Partner"}
            value={data.email}
            onChange={onChange}
            keys={"email"}
            disabled={disabled}
            colType={"underlabel"}
          />
          <ImageUpload
            label={"Photo"}
            value={data.picture_url}
            onChange={onChange}
            keys={"picture_url"}
            disabled={disabled}
            colType={"underlabel"}
          />
          <MapsCustom
            label={"Lokasi"}
            onChange={onChange}
            value={data.location}
            keys={"location"}
            disabled={disabled}
            colType={"underlabel"}
          />
        </div>
        <div style={{ width: "100%" }}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ width: "30%" }}>
              <Row gutter={14} style={{ marginBottom: "10px" }}>
                <Col span={20}>Tipe Partner</Col>
                <Col span={10}>
                  <Image
                    width={100}
                    src={
                      data.icon_url
                        ? data.icon_url
                        : "https://st2.depositphotos.com/1104517/11965/v/600/depositphotos_119659092-stock-illustration-male-avatar-profile-picture-vector.jpg"
                    }
                  />
                </Col>
              </Row>
            </div>
            <div style={{ width: "70%" }}>
              <TipeDokter
                label={" "}
                value={data}
                onChange={onChange}
                keys={[
                  "partner_type",
                  "description",
                  "icon_url",
                ]}
                disabled={disabled}
                colType={"underlabel"}
              />
            </div>
          </div>
          <InputPicker
            label={"Nomor / Tanggal Akhir Berlaku Izin"}
            value={data}
            onChange={onChange}
            keys={["license_number", "license_expired_date"]}
            disabled={disabled}
            colType={"underlabel"}
          />
          <DatePickerCustom
            label={"Tanggal Berdiri"}
            value={data.founded}
            onChange={onChange}
            keys={"founded"}
            disabled={disabled}
            colType={"underlabel"}
          />
          <TextAreaCustom
            label={"Alamat"}
            value={data.address}
            onChange={onChange}
            keys={"address"}
            disabled={disabled}
            colType={"underlabel"}
          />
          <TextAreaCustom
            label={"Deskripsi"}
            value={data.description}
            onChange={onChange}
            keys={"description"}
            disabled={disabled}
            colType={"underlabel"}
          />
          {/* <InputCustom
            label={"Nomor Lisensi"}
            value={data.license_number}
            onChange={onChange}
            keys={"license_number"}
            disabled={disabled}
            colType={"underlabel"}
          />
          <DatePickerCustom
            label={"Tanggal Expired"}
            value={data.license_expired_date}
            onChange={onChange}
            keys={"license_expired_date"}
            disabled={disabled}
            colType={"underlabel"}
          /> */}
        </div>
      </div>
      <br />
      <br />
      <h3>Penanggung Jawab</h3>
      <hr />
      <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
        <div style={{ width: "100%" }}>
          <InputCustom
            label={"Dokter Penanggung Jawab"}
            value={data.doctor_in_charge}
            onChange={onChange}
            keys={"doctor_in_charge"}
            disabled={disabled}
            colType={"underlabel"}
          />
          <InputCustom
            label={"Nomor SIP"}
            value={data.doctor_in_charge_sip}
            onChange={onChange}
            keys={"doctor_in_charge_sip"}
            disabled={disabled}
            colType={"underlabel"}
          />
        </div>
        <div style={{ width: "100%" }}>
          <TextAreaCustom
            label={"Keterangan"}
            value={data.doctor_in_charge_description}
            onChange={onChange}
            keys={"doctor_in_charge_description"}
            disabled={disabled}
            colType={"underlabel"}
          />
        </div>
      </div>
      <br />
      <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
        <div>
          <Button onClick={() => back(false)}>Back</Button>
        </div>
        <div style={{ marginLeft: "auto" }}>
          <Button
            type="primary"
            loading={loading}
            disabled={disabled}
            onClick={() => save()}
          >
            Simpan
          </Button>
        </div>
      </div>
    </div>
  );
};


export default Profile;

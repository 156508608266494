import { Col, Input, Row } from "antd";
import React from "react";

const { TextArea } = Input;

const TextAreaCustom = (props) => {
  const { label, value, onChange, keys, disabled, colType } = props;

  const changeHandler = (e) => {
    onChange({ [keys]: e.target.value });
  };

  return (
    <Row gutter={14} style={{ marginBottom: "10px" }}>
      <Col span={colType === "underlabel" ? 22 : 6}>
        <label>{label}</label>
      </Col>
      <Col span={colType === "underlabel" ? 22 : 10}>
        <TextArea
          style={{ width: "100%" }}
          disabled={disabled}
          rows={4}
          value={value}
          onChange={(e) => changeHandler(e)}
        />
      </Col>
    </Row>
  );
};

export default TextAreaCustom;

import { Table, Tooltip, Button, message, Skeleton, Tag, Modal } from "antd";
import React, { useEffect, useState } from "react";
import {
  EditOutlined,
  DeleteOutlined,
  FolderOpenOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  getServicePrice,
  addPrice,
  putPrice,
  deletePrice,
  getTotalSercicePrice,
} from "../../../../app/API/AP_API";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import confirm from "antd/lib/modal/confirm";
import InputCustom from "../../../Components/InputCustom";
import TextAreaCustom from "../../../Components/TextAreaCustom";
import ImageUpload from "../../../Components/ImageUpload";
import TableCustom from "../../../Components/TableCustom";
import InputNumberCustom from "../../../Components/InputNumberCustom";
import SelectServicePrice from "../Components/SelectServicePrice"
import SelectFeeType from "../Components/SelectFeeType";
import SelectShareFeeFor from "../Components/SelectShareFeeFor";
import DropdownFetch from '../../../Components/DropdownDoctor';
const { REACT_APP_API } = process.env;
const ListServicePrice = (props) => {
  const [visible, setVisible] = useState(false);
  const [feeType, setFeeType] = useState(false);
  const [discType, setDiscType] = useState(false);
  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState(false);
  const [addEditCond, setCond] = useState(true);
  const [add, setAdd] = useState(false);
  const [view, setView] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rowData, setRowData] = useState({});
  const [dataTable, setData] = useState([]);
  const [sendData, setSendData] = useState({});
  const [trigger, setTrigger] = useState(false);
  const [total, setTotal] = useState(0);
  const [parameter, setParameter] = useState("");
  const [pagination, setPagination] = useState("?page=1&limit=10");

  const column = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
    },
    {
      title: "Doctor",
      dataIndex: "doctor_name",
      key: "doctor_name",
    },
    {
      title: "Service Name",
      dataIndex: "service_id",
      render: (text, color) => {
        if (text == 1) {
          text = "TeleChat";
          color = 'green'
        } else if (text == 2) {
          text = "TeleVcall";
          color = 'blue'
        } else if (text == 3) {
          text = "Visit Dokter";
          color = 'magenta'
        } else if (text == 4) {
          text = "TeleVaksin";
          color = 'yellow'
        } else {
          text = "Uncategorized";
        }
        return <Tag color={color}>{text}</Tag>;
      }
    },
    {
      title: "Share fee type",
      dataIndex: "share_fee_type",
      key: "share_fee_type",
    },
    {
      title: "Discount type",
      dataIndex: "discount_type",
      key: "discount_type",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "",
      dataIndex: "operation",
      width: 100,
      fixed: "right",
      render: (text, record) => (
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <Tooltip placement="top" title={"Edit"}>
            <Button
              icon={<EditOutlined />}
              type="primary"
              loading={loading}
              onClick={() => {
                if (record.discount_type == 'percent') {
                  setDiscType(true);
                } else if (record.discount_type == 'nominal') {
                  setDiscType(false);
                }
                if (record.share_fee_type == 'percent') {
                  setFeeType(true);
                } else if (record.share_fee_type == 'nominal') {
                  setFeeType(false);
                }
                setRowData(record);
                setEdit(true);
                setVisible(true);
              }}
            />
          </Tooltip>
          <Tooltip placement="top" title={"Hapus"}>
            <Button
              icon={<DeleteOutlined />}
              type="danger"
              onClick={() => {
                showConfirm(record.id);
              }}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  useEffect(() => {
    const getApi = async () => {
      setLoading(true);
      try {
        const { data } = await getServicePrice(pagination, parameter);
        const total = await getTotalSercicePrice();
        if (data.message === "Success") {
          const dataFilter = data.data?.filter((e, i) => {
            return (e.no = i + 1);
          });
          setData(dataFilter);
          setTotal(total.data.data);
        }
      } catch (e) {
        console.warn("error get doctor", e);
        message.error("Terjadi Kesalahan, Silahkan Coba Nanti");
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };
    getApi();
  }, [addEditCond, trigger, pagination, parameter]);

  const handleOk = async (e) => {
    setLoading(true);
    const sendData = {
      ...rowData,
    };
    delete sendData.no;
    delete sendData.id;
    delete sendData.created_at;
    delete sendData.updated_at;
    delete sendData.doctor_name;
    delete sendData.renter_fee;
    delete sendData.tenant_fee;
    if (sendData.discount_type == 'percent') {
      delete sendData.discount_nominal
    } else if (sendData.discount_type == 'nominal') {
      delete sendData.discount_percent
    }
    if (sendData.share_fee_type == 'percent') {
      delete sendData.share_fee_nominal
    } else if (sendData.share_fee_type == 'nominal') {
      delete sendData.share_fee_percent
    }
    try {
      const data = edit
        ? await putPrice(sendData, rowData.id)
        : await addPrice([sendData]);
      if (data.data.message === "Success") {
        message.success("Data Berhasil Ditambahkan");
        setVisible(e);
        setRowData({});
      }
      console.log("data update", { data });
    } catch (e) {
      console.warn("error put doctor", e);
      message.error("Terjadi Kesalahan, Silahkan Coba Nanti");
      setLoading(false);
    } finally {
      setLoading(false);
      //setEdit(false);
      setTrigger(!trigger);
    }
  };

  function showConfirm(e) {
    confirm({
      title: "Yakin Menghapus Data ?",
      icon: <ExclamationCircleOutlined />,
      content: "",
      onOk() {
        handleDelete(e);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  }

  const handleDelete = async (e) => {
    setLoading(true);
    try {
      const dataDelete = await deletePrice(e);
      if (dataDelete.data.status === 200) {
        message.success("Data Berhasil Dihapus");
        setLoading(false);
        setTrigger(!trigger);
      }
    } catch (e) {
      console.warn("error post lisensi", e);
      message.error("Terjadi Kesalahan, Silahkan Coba Nanti");
      setLoading(false);
    }
  };

  const changeHandler = (e) => {
    const temp = { ...rowData, ...e };
    console.log({ temp });
    setRowData(temp);
    if (e.share_fee_type == 'percent' ) {
      setFeeType(true);
    } else if (e.share_fee_type == 'nominal' ) {
      setFeeType(false);
    }
    if (e.discount_type == 'percent') {
      setDiscType(true);
    } else if (e.discount_type == 'nominal') {
      setDiscType(false);
    }
  };

  const handleCancel = () => {
    setVisible(false);
    setEdit(false);
    setRowData({});
  };

  return (
    <>
      <div>
        <Button
          style={{ backgroundColor: "green", color: "white" }}
          icon={<AddCircleOutlineIcon />}
          onClick={() => {
            setRowData({});
            setVisible(true);
          }}
        >
           Tambah
        </Button>
      </div>
      <br />
      <TableCustom
        columns={column}
        data={dataTable}
        total={total}
        loading={loading}
        setParameter={(e) => setParameter(e)}
        except={["no", "operation"]}
        setPagination={(e) => setPagination(e)}
        startInOne={true}
      />
      {
        visible ? (
          <Modal
            title="Service Price"
            width={800}
            visible={visible}
            closable={true}
            footer={null}
            onCancel={handleCancel}
          > 
            <div>
              <DropdownFetch
                label={"Doctor"}
                // url={`${REACT_APP_API}listdoctor`}
                value={rowData.doctor_id}
                onChange={(e) => changeHandler(e)}
                keys={["doctor_id"]}
                datapick={"id"}
                disabled={edit}
                nameValue={"name"}
                flagLevel={true}
                keyValue={"id"}
              />
              <SelectServicePrice label={'Service'} value={rowData.service_id} onChange={(e) => changeHandler(e)} keys={["service_id"]} />
              <SelectShareFeeFor label={'Share Fee For'} value={rowData.share_fee_for} onChange={(e) => changeHandler(e)} keys={["share_fee_for"]} />
              <SelectFeeType label={'Share Fee Type'} value={rowData.share_fee_type} onChange={(e) => changeHandler(e)} keys={["share_fee_type"]} />
              { feeType ? (
                <InputNumberCustom
                  label={"Fee Percent"}
                  value={rowData.share_fee_percent}
                  onChange={(e) => changeHandler(e)}
                  keys={"share_fee_percent"}
                />
              ) : (
                  <InputNumberCustom
                    label={"Fee Nominal"}
                    value={rowData.share_fee_nominal}
                    onChange={(e) => changeHandler(e)}
                    keys={"share_fee_nominal"}
                  />
              )}
              <SelectFeeType label={'Discount Type'} value={rowData.discount_type} onChange={(e) => changeHandler(e)} keys={["discount_type"]} />
              {discType ? (
                <InputNumberCustom
                    label={"Discount percent"}
                    value={rowData.discount_percent}
                    onChange={(e) => changeHandler(e)}
                    keys={"discount_percent"}
                  />
              ) : (
                  <InputNumberCustom
                    label={"Discount Nominal"}
                    value={rowData.discount_nominal}
                    onChange={(e) => changeHandler(e)}
                    keys={"discount_nominal"}
                  />
              )}
              
              
              <InputNumberCustom
                label={"Price"}
                value={rowData.price}
                onChange={(e) => changeHandler(e)}
                keys={"price"}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  marginTop: 30,
                }}
              >
                <div style={{ marginLeft: "auto" }}>
                  <Button onClick={() => handleCancel(false)}>Batal</Button>
                </div>
                <div style={{ marginLeft: "10px" }}>
                  <Button
                    type="primary"
                    loading={loading}
                    onClick={() => handleOk(false)}
                  >
                    Simpan
                  </Button>
                </div>
              </div>
            </div>
          </Modal>

        ) : null
      }
      
    </>
  );
};

export default ListServicePrice;

import { Table, Tooltip, Button, message, Skeleton, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { MoreOutlined, CheckOutlined } from "@ant-design/icons";
import {
  getListPartner,
  getListLisensi,
  getService,
  putPartner,
  putStatusPartner,
  countPartner
} from "../../../../API/AP_API";
import moment from "moment";
import DataPartner from "./DataPartner";
import TableCustom from "../../../../Components/TableCustom";

const ListDoctor = (props) => {
  const [show, setShow] = useState(false);
  const [add, setAdd] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rowData, setRowData] = useState({});
  const [dataTable, setData] = useState([]);
  const [dataLisensi, setDataLisensi] = useState([]);
  const [sendData, setSendData] = useState({});
  const [visible, setVisible] = useState(false);
  const [visibleStatus, setVisibleStatus] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [total, setTotal] = useState(0);
  const [parameter, setParameter] = useState("&registration_status=pending&registration_status=rejected");
  const [dataService, setDataService] = useState([]);
  const [pagination, setPagination] = useState("?page=1&limit=5");

  const column = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
    },
    {
      title: "Nama",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "No Hp",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Waktu Interview",
      dataIndex: "registration_interview_date",
      key: "registration_interview_date",
      render: (text, record) => (
        <div>
          {text !== null ? moment(text, "YYYY-MM-DD").format("DD-MM-YYYY") : ""}
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "registration_status",
      key: "registration_status",
      render: (text, record) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            backgroundColor:
              record.registration_status === "accepted"
                ? "lightgreen"
                : record.registration_status === "rejected"
                ? "#f77375"
                : "orange",
            borderRadius: "20px",
            padding: "7px",
          }}
        >
          {text}
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "operation",
      width: 100,
      fixed: "right",
      render: (text, record) => (
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <Tooltip placement="top" title={"Edit"}>
            <Button
              icon={<MoreOutlined />}
              type="primary"
              onClick={() => {
                setRowData(record);
                setVisible(true);
              }}
            />
          </Tooltip>
          <Tooltip placement="top" title={"Status"}>
            <Button
              icon={<CheckOutlined />}
              type="primary"
              onClick={() => {
                setVisibleStatus(true);
                setRowData(record);
              }}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  const getServiceAvailable = async (e) => {
    setLoading(true);
    try {
      const { data } = await getService(e);
      if (data.message === "Success") {
        const dokumen = data.data;
        setDataService(dokumen);
        console.log("data service", { dokumen });
      }
    } catch (e) {
      console.warn("error get doctor", e);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const getApi = async () => {
      setLoading(true);
      try {
        const { data } = await getListPartner(pagination, parameter);
        if (data.message === "Success") {
          const dataFilter = data.data?.filter((e, i) => {
            console.log(data)
            return e.registration_status !== 'accepted'
          });
          const filter = dataFilter.filter((v, i) => {
            return (v.no = i + 1)
          })
          const total = await countPartner();
          setTotal(total.data.data);
          setData(filter);
        }
      } catch (e) {
        console.warn("error get doctor", e);
        message.error("Terjadi Kesalahan, Silahkan Coba Nanti");
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };
    getApi();
  }, [show, trigger, pagination, parameter]);

  const getLicense = async (e) => {
    setLoading(true);
    try {
      const { data } = await getListLisensi(e);
      if (data.message === "Success") {
        const dokumen = data.data;
        setDataLisensi(dokumen);
      }
    } catch (e) {
      console.warn("error get doctor", e);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const putDoctorProfile = async () => {
    setLoading(true);
    try {
      const data = await putPartner(sendData);
      console.log("data", { data });
      if (data.data.message === "Success") {
        message.success("Data Berhasil Ditambahkan");
      }
      console.log("data update", { data });
    } catch (e) {
      console.warn("error put doctor", e);
      message.error("Terjadi Kesalahan, Silahkan Coba Nanti");
      setLoading(false);
    } finally {
      setLoading(false);
      setSendData({});
    }
  };

  const updateStatus = async (e) => {
    setLoading(true);
    const param = e.slice(0, 6);
    const id = rowData.id;
    const body = {
      price_level_id: rowData.price_level_id,
      registration_number: rowData.registration_number,
      registration_code: rowData.registration_code,
      registration_interview_date: rowData.registration_interview_date,
      registration_expire: rowData.registration_expire,
      registration_status: e,
      created_at: rowData.created_at,
      updated_at: rowData.updated_at,
      is_on_service: rowData.is_on_service,
      doctor_type: rowData.doctor_type,
      name: rowData.name,
      phone: rowData.phone,
      email: rowData.email,
      status: e === "accepted" ? "active" : "inactive",
      otp_created: rowData.otp_created,
      is_visible: rowData.is_visible,
      location: rowData.location,
      device: rowData.device,
      verification_token: rowData.verification_token,
      verification_expired: rowData.verification_expired,
    };
    try {
      const data = await putStatusPartner(body, param, id);
      console.log("data", { data });
      if (data.data.message === "Success") {
        message.success("Data Berhasil Diperbaharui");
      }
      console.log("data update", { data });
    } catch (e) {
      console.warn("error put doctor", e);
      message.error("Terjadi Kesalahan, Silahkan Coba Nanti");
      setLoading(false);
    } finally {
      setLoading(false);
      setTrigger(!trigger);
      setVisibleStatus(false);
    }
  };

  const handleOk = (e) => {
    setVisible(false);
    getLicense(rowData.id);
    getServiceAvailable(rowData.id);
    setShow(e);
    setAdd(e);
  };

  const changeHandler = (e) => {
    const temp = { ...rowData, ...e };
    const body = {
      ...sendData,
      ...e,
      id: temp.id,
      location: `(${temp.location.x},${temp.location.y})`,
    };
    delete body.icon_url;
    setRowData(temp);
    setSendData(body);
  };

  return (
    <>
      {show ? (
        <DataPartner
          data={rowData}
          dataLisensi={dataLisensi}
          dataService={dataService}
          onChange={(e) => changeHandler(e)}
          reloadService={(e) => getServiceAvailable(e)}
          loading={loading}
          back={(e) => {
            setShow(e);
          }}
          putDoctor={() => putDoctorProfile()}
          getLicense={(e) => getLicense(e)}
          flagAdd={add}
        />
      ) : (
        <TableCustom
          columns={column}
          data={dataTable}
          total={total}
          loading={loading}
          setParameter={(e) => setParameter(e)}
          except={["no", "operation"]}
          setPagination={(e) => setPagination(e)}
          //noPagination={true}
          startInOne={true}
        />
      )}

      <Modal
        title="Mendaftar Sebagai Partner ?"
        width={800}
        visible={visible}
        closable={false}
        footer={null}
        onCancel={() => setVisible(false)}
      >
        <div>
          <div>
            <label>
              Jika mendaftar sebagai partner, klik "daftar partner" untuk
              meneruskan ke pendataan partner.
            </label>
          </div>
          <br />
          <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <div style={{ marginLeft: "auto" }}>
              <Button onClick={() => setVisible(false)}>Partner Lainnya</Button>
            </div>
            <div style={{ marginLeft: "10px" }}>
              <Button
                type="primary"
                loading={loading}
                onClick={() => handleOk(true)}
              >
                Daftarkan ke Partner
              </Button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        title="Notifikasi terima partner"
        width={800}
        visible={visibleStatus}
        footer={null}
        closable={false}
        onCancel={() => setVisibleStatus(false)}
      >
        <div>
          <div>
            <label>
              Klik terima untuk melanjutkan validasi data partner dan klik tolak
              untuk tolak partner
            </label>
          </div>
          <br />
          <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <div>
              <Button onClick={() => setVisibleStatus(false)}>Batal</Button>
            </div>
            <div style={{ marginLeft: "auto" }}>
              <Button type="danger" onClick={() => updateStatus("rejected")}>Tolak</Button>
            </div>
            <div style={{ marginLeft: "10px" }}>
              <Button
                type="primary"
                loading={loading}
                onClick={() => updateStatus("accepted")}
              >
                Terima
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ListDoctor;

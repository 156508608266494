import React, { useEffect } from "react"
import { useState } from "react"
import { Row, Col, Button, Table, message } from "antd"
import { getSchedule, deleteSchedule } from "../../../API/AP_API"
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons"
import ModalFormAddComponent from "../../../Components/ModalFormAddComponent"
import jwt_decode from "jwt-decode"

const JadwalPartnerComponent = (props) => {
  const [openModal, setOpenModal] = useState(false)
  const [dataTable, setDataTable] = useState([])
  const [manipulateData, setManipulateData] = useState([])
  const [dataIdTipe, setDataIdTipe] = useState(null)
  const [dataIdTipeLayanan, setDataIdTipeLayanan] = useState(null)
  const [dataIdHari, setDataIdHari] = useState(null)
  const [dataIdTime, setDataIdTime] = useState([{
    startTime: null,
    endTime: null
  }])
  const [idSchedule, setIdSchedule] = useState(null)
  const [conditionalEdit, setConditionalEdit] = useState(false)
  const [pagination, setPagination] = useState("?page=1&limit=1000");
  const [populateData, setPopulateData] = useState(false)
  const { data, back, disabled } = props
  const idPartner = data.id
  const token = localStorage.getItem("token")
  const decode = jwt_decode(token)
  const userId = decode.id

  useEffect(() => {

    const getApi = async () => {
      try {
        const { data } = await getSchedule(pagination, `&partner_id=${idPartner}`)
        setDataTable(data.data)
      }catch(err) {
        console.log(err, "==>ERROR");
        message.error("Terjadi Kesalahan, Silahkan Coba Nanti");
      }
    }
    setPopulateData(false)
    getApi()
  }, [populateData])

  useEffect(() => {
    let result = []
    let tmpChatPraktek = []
    let tmpChatKonsultasi = []
    let tmpVcallPraktek = []
    let tmpVcallKonsultasi = []
    let tmpVisitPraktek = []
    let tmpVisitKonsultasi = []
    let tmpVaksinLabPraktek = []
    let tmpVaksinLabKonsultasi = []
    let tmpMedsPraktek = []
    let tmpMedsKonsultasi = []

    dataTable.forEach(data => {
      if (data.service_id === 1 && data.type === "main") {
        console.log(data, "=> okChat Praktek");
        tmpChatPraktek.push(data)
      }else if (data.service_id === 1 && data.type === "secondary") {
        tmpChatKonsultasi.push(data)
      }else if (data.service_id === 2 && data.type === "main") {
        tmpVcallPraktek.push(data)
      }else if (data.service_id === 2 && data.type === "secondary") {
        tmpVcallKonsultasi.push(data)
      }else if (data.service_id === 3 && data.type === "main") {
        tmpVisitPraktek.push(data)
      }else if (data.service_id === 3 && data.type === "secondary") {
        tmpVisitKonsultasi.push(data)
      }else if (data.service_id === 4 && data.type === "main") {
        tmpVaksinLabPraktek.push(data)
      }else if (data.service_id === 4 && data.type === "secondary") {
        tmpVaksinLabKonsultasi.push(data)
      }else if (data.service_id === 5 && data.type === "main") {
        tmpMedsPraktek.push(data)
      }else if (data.service_id === 5 && data.type === "secondary") {
        tmpMedsKonsultasi.push(data)
      }
    })
    // ===> OkChat Jadwal Praktek
    if (tmpChatPraktek.length > 0) {
      let resultManipulateData = manipulateDataJadwal("Telechat", "Jadwal Praktek", tmpChatPraktek)
      result.push(resultManipulateData)
    }
    // ===> OkChat Jadwal Konsultasi
    if (tmpChatKonsultasi.length > 0) {
      let resultManipulateData = manipulateDataJadwal("Telechat", "Jadwal Konsultasi", tmpChatKonsultasi)
      result.push(resultManipulateData)
    }
    // ===> OkVcall Jadwal Praktek
    if (tmpVcallPraktek.length > 0) {
      let resultManipulateData = manipulateDataJadwal("Televcall", "Jadwal Praktek", tmpVcallPraktek)
      result.push(resultManipulateData)
    }
    //===> OkVcall Jadwal Konsultasi
    if (tmpVcallKonsultasi.length > 0) {
      let resultManipulateData = manipulateDataJadwal("Televcall", "Jadwal Konsultasi", tmpVcallKonsultasi)
      result.push(resultManipulateData)
    }
    //===> OkVisit Jadwal Praktek
    if (tmpVisitPraktek.length > 0) {
      let resultManipulateData = manipulateDataJadwal("Televisit", "Jadwal Praktek", tmpVisitPraktek)
      result.push(resultManipulateData)
    }
    //===> OkVisit Jadwal Konsultasi
    if (tmpVisitKonsultasi.length > 0) {
      let resultManipulateData = manipulateDataJadwal("Televisit", "Jadwal Konsultasi", tmpVisitKonsultasi)
      result.push(resultManipulateData)
    }
    //===> OkVaksinLab Jadwal Praktek
    if (tmpVaksinLabPraktek.length > 0) {
      let resultManipulateData = manipulateDataJadwal("Televaksin/lab", "Jadwal Praktek", tmpVaksinLabPraktek)
      result.push(resultManipulateData)
    }
    //===> OkVaksinLab Jadwal Konsultasi
    if (tmpVaksinLabKonsultasi.length > 0) {
      let resultManipulateData = manipulateDataJadwal("Televaksin/lab", "Jadwal Konsultasi", tmpVaksinLabKonsultasi)
      result.push(resultManipulateData)
    }
    //===> OkMeds Jadwal Praktek
    if (tmpMedsPraktek.length > 0) {
      let resultManipulateData = manipulateDataJadwal("TeleMeds", "Jadwal Praktek", tmpMedsPraktek)
      result.push(resultManipulateData)
    }
    //===> OkMeds Jadwal Konsultasi
    if (tmpMedsKonsultasi.length > 0) {
      let resultManipulateData = manipulateDataJadwal("TeleMeds", "Jadwal Konsultasi", tmpMedsKonsultasi)
      result.push(resultManipulateData)
    }
    setManipulateData(result)
  }, [dataTable])

  const column = [
    {
      title: "Layanan",
      dataIndex: "layanan",
      key: "layanan",
    },
    {
      title: "Tipe Layanan",
      dataIndex: "tipeLayanan",
      key: "tipeLayanan",
    },
    {
      title: "Jadwal",
      dataIndex: "jadwal",
      key: "jadwal",
      render: (value) => {
        return (
          <div>
            {value.map((jadwal, index) => (
              <>
                <Row key={index}>
                  <Col 
                  span={6} 
                  offset={0} 
                  style={{marginBottom: "6px", marginTop: "13px"}}
                  > 
                    {translateDay(jadwal.day)}
                  </Col>
                  <Col span={14} offset={1}>
                    {jadwal.jadwal.map((jam) => (
                      <>
                        <div style={{marginBottom: "6px", marginTop: "13px"}}>{`${jam.start_time} - ${jam.end_time}`}</div>
                      </>
                    ))}
                  </Col>
                </Row>
              </>
            ))}
          </div>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "jadwal",
      key: "jadwal",
      render: (value) => {
        return(
          <>
            {
              value.map((el, index) => (
                <>
                {
                  el.id.map((id) => (
                    <>
                      <Row>
                        <Col span={6} offset={2} style={{marginTop: "5px"}}>
                          <Button
                            type="text"
                            icon={<EditOutlined />}
                            key={id}
                            onClick={() => handleEditDataById(id)}
                            loading={populateData} 
                            disabled={disabled}
                          />
                        </Col>
                        <Col span={6} offset={6} style={{marginTop: "5px"}}>
                          <Button
                              type="text"
                              icon={<DeleteOutlined />}
                              key={id}
                              onClick={() => handleDeleteDataById(id)}
                              loading={populateData}
                              disabled={disabled}
                          />
                        </Col>
                      </Row>
                    </>
                  ))
                }
                </>
              ))
            }
          </>
        )
      }
    }
  ];
    
  const translateDay = (value) => {
    let v;
    switch (value) {
      case "sunday":
        v = "Minggu";
        break;
      case "monday":
        v = "Senin";
        break;
      case "tuesday":
        v = "Selasa";
        break;
      case "wednesday":
        v = "Rabu";
        break;
      case "thursday":
        v = "Kamis";
        break;
      case "friday":
        v = "Jumat";
        break;
      case "saturday":
        v = "Sabtu";
        break;
      default:
        v = value;
    }
    return v;
  };
    
  const manipulateDataJadwal = (layanan, tipeLayanan, arrJadwal) => {
    let tmpArrDay = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"]
    let tmpObj = {
      layanan: layanan,
      tipeLayanan: tipeLayanan,
      jadwal: []
    }
    tmpArrDay.forEach(el => {
      arrJadwal.filter(el2 => {
        return el === el2.day
      }).map((el3, index) => {
        let dayNow = el3.day
        if(index === 0) {
          let tmpJadwal = tmpObj.jadwal
          let inputUser = {
            day: el3.day,
            jadwal: [{
              start_time: el3.start_time,
              end_time: el3.end_time
            }],
            id: [el3.id]
          }
          tmpJadwal.push(inputUser)
          return null;
        }
        const sameDay = arrJadwal.filter(a => a.day === dayNow);
        if (sameDay.length > 0) {
          let tmpJadwal = tmpObj.jadwal
          let arrIndexZero = tmpJadwal.filter(a => a.day === dayNow)
          arrIndexZero[0].jadwal.push({
              start_time: el3.start_time,
              end_time: el3.end_time
          })
          arrIndexZero[0].id.push(el3.id)
        }
      })
    })
    return tmpObj
  }

  const fromChildren = (paramVisible, paramSet) => {
    setConditionalEdit(false)
    setOpenModal(paramVisible)
    setPopulateData(paramSet)
  }

  const layananFunc = (val) => {
    let v = String(val);
    const db = {
      "1": "Telechat",
      "2": "TeleVcall",
      "3": "TeleVisit",
      "4": "TeleVaksinLab",
      "5": "TeleMeds"
    };
    return db[v] || "Tidak Terdefinisi";
  };

  const typeLayananFunc = (val) => {
    let v = String(val);
    const db = {
      main: "Jadwal Praktek",
      secondary: "Jadwal Konsultasi",
    };
    return db[v] || "Tidak Terdefinisi";
  };

  const handleEditDataById = (id) => {
    dataTable.forEach(el => {
      const idDataBase = el.id
      if (idDataBase === id) {
        setDataIdTipe(layananFunc(el.service_id))
        setDataIdTipeLayanan(typeLayananFunc(el.type))
        setDataIdHari(el.day)
        setIdSchedule(idDataBase)
        const time = [{
          startTime: el.start_time,
          endTime: el.end_time
        }]
        setDataIdTime(time)
        setConditionalEdit(true)
      }
    })
    setOpenModal(true)
  }

  const handleDeleteDataById = (id) => {
    requestApiDelete(id)
    setPopulateData(true)
  }

  const requestApiDelete = async (id) => {
    await deleteSchedule(id)
  }

  return(
    <>
      <Row>
          <Col span={24}>Jadwal Praktek</Col>
      </Row>
      <div style={{ textAlign: "right" }}>
      <br />
          <Button
            style={{ backgroundColor: "blue", color: "white" }}
            icon={<PlusOutlined />}
            onClick={() => { setOpenModal(true) }}
            disabled={disabled}
          >
            Tambah Data
          </Button>
      </div>
      <br />
      <Table
        columns={column}
        dataSource={manipulateData}
        footer={false} 
      />
      <ModalFormAddComponent 
        dataDoctor={idPartner}
        userId={userId}
        openModal={openModal}
        fromChildren={fromChildren}
        tipeId={dataIdTipe}
        tipeLayananId={dataIdTipeLayanan}
        dayId={dataIdHari}
        timeId={dataIdTime}
        conditionalRendering={conditionalEdit}
        scheduleId={idSchedule}
        isPartner={true}
      />
      <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
        <div>
          <Button onClick={() => back(false)}>Back</Button>
        </div>
      </div>
    </>
  )

  /** DEVELOPED WITH 💖 By Febrian Aditya */
}

export default JadwalPartnerComponent

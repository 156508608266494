import { Col, Input, Form, Row, DatePicker, InputNumber } from "antd";
import React from "react";

const InputNumberCustom = (props) => {
  const {
    label,
    value,
    onChange,
    keys,
    disabled,
    validateStatus,
    colType,
  } = props;

  const changeHandler = (e) => {
    onChange({ [keys]: e });
  };

  return (
    <Row gutter={14} style={{ marginBottom: "10px" }}>
      <Col span={colType === "underlabel" ? 22 : 6}>
        <label>{label}</label>
      </Col>
      <Col span={colType === "underlabel" ? 22 : 10}>
        <Form.Item validateStatus={validateStatus} help={null}>
          <InputNumber
            type={"number"}
            style={{ width: "100%" }}
            disabled={disabled}
            value={value}
            onChange={(e) => changeHandler(e)}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default InputNumberCustom;

import { Col, DatePicker, Input, Row } from "antd";
import moment from "moment";
import React from "react";

const DatePickerCustom = (props) => {
  const { label, value, onChange, keys, disabled, colType } = props;

  return (
    <Row gutter={14} style={{ marginBottom: "10px" }}>
      <Col span={colType === "underlabel" ? 22 : 6}>
        <label>{label}</label>
      </Col>
      <Col span={colType === "underlabel" ? 22 : 10}>
        <DatePicker
          disabled={disabled}
          format={"DD-MM-YYYY"}
          style={{ width: "100%" }}
          value={value ? moment(value, "DD-MM-YYYY") : undefined}
          maxLength={50}
          onChange={(e, date) => {
            const temp = moment(e, "DD-MM-YYYY");
            onChange({ [keys]: temp });
          }}
        />
      </Col>
    </Row>
  );
};

export default DatePickerCustom;

import { Tabs } from "antd";
import React from "react";
import Profile from "../Components/Profile";
import JadwalPartnerComponent from "../../Components/JadwalComponentPartner";

const { TabPane } = Tabs;
const DataDoctor = (props) => {
  const {
    back,
    data,
    onChange,
    putDoctor,
    loading,
    getLicense,
    dataLisensi,
    disabled,
    dataService,
    reloadService,
  } = props;

  return (
    <div>
      <Tabs defaultActiveKey="1" type="line">
        <TabPane tab="Profil" key="1">
          <Profile
            data={data}
            onChange={onChange}
            back={back}
            save={putDoctor}
            loading={loading}
            disabled={disabled}
          />
        </TabPane>
        <TabPane tab="Jadwal Praktek" key="2">
          <JadwalPartnerComponent data={data} back={back} disabled={disabled}/>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default DataDoctor;

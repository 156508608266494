import React from 'react';
import { TimePicker } from 'antd';
import moment, { localeData } from "moment"

const TimePickerComponent = (props) => {

    const onChange = (time, timeString) => {
        props.onChange(props.index, timeString)
    };
    
    return (
        <>
            <TimePicker 
                onChange={onChange}
                value={props.value}
                allowClear={false}
                style={{width: "140px"}}
            />
        </>
    )

    /** DEVELOPED WITH 💖 By Febrian Aditya */
}

export default TimePickerComponent

import { Card } from 'antd'
import React from 'react'
import { Fragment } from 'react'
import ListDoctor from './Form/ListDoctor'

const IndexDoctor = () => {
    return (
        <Fragment>
            <Card title="Partner">
                <ListDoctor />
            </Card>
        </Fragment>
    )
}

export default IndexDoctor

import { Tooltip, Button, message, Modal } from "antd";
import React, { useEffect, useState } from "react";
import DataDoctor from "../../Registration/Form/DataPartner";
import {
  EditOutlined,
  FolderOpenOutlined,
  CloseCircleOutlined,
  EyeOutlined
} from "@ant-design/icons";
import {
  countPartner,
  getListPartner,
  getListLisensi,
  getService,
  putPartner,
  putStatusPartner,
} from "../../../../API/AP_API";
import TableCustom from "../../../../Components/TableCustom";

const ListDoctor = (props) => {
  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState(false);
  const [view, setView] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rowData, setRowData] = useState({});
  const [dataTable, setData] = useState([]);
  const [sendData, setSendData] = useState({});
  const [dataLisensi, setDataLisensi] = useState([]);
  const [dataService, setDataService] = useState([]);
  const [total, setTotal] = useState(0);
  const [parameter, setParameter] = useState("");
  const [pagination, setPagination] = useState("?page=1&limit=10");
  const [visibleStatus, setVisibleStatus] = useState(false);
  const [trigger, setTrigger] = useState(false);

  const column = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
    },
    {
      title: "Name Partner",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Tipe Partner",
      dataIndex: "partner_type",
      key: "partner_type",
    },
    // {
    //   title: "Level Price",
    //   dataIndex: "price_level_id",
    //   key: "price_level_id",
    //   render: (text, record) => <div>Level {text}</div>,
    // },
    {
      title: "Status Aktifasi",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            backgroundColor:
              record.status === "active"
                ? "lightgreen"
                : record.status === "inactive"
                ? "#f77375"
                : "orange",
            borderRadius: "20px",
            padding: "7px",
          }}
        >
          {record.status === "active" ? "Aktif" : record.status === "inactive" ? "Nonaktif" : "Pending"}
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "operation",
      width: 100,
      fixed: "right",
      render: (text, record) => (
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <Tooltip placement="top" title={"Edit"}>
            <Button
              icon={<EditOutlined />}
              type="primary"
              loading={edit}
              onClick={() => {
                setRowData(record);
                getLicense(record.id);
                getServiceAvailable(record.id);
                setShow(true);
                setEdit(true);
              }}
            />
          </Tooltip>
          <Tooltip placement="top" title={"View"}>
            <Button
              icon={<EyeOutlined />}
              loading={view}
              onClick={() => {
                setRowData(record);
                getLicense(record.id);
                getServiceAvailable(record.id);
                setShow(true);
                setView(true);
              }}
            />
          </Tooltip>
          <Tooltip placement="top" title={"Nonaktifkan"}>
            <Button
              icon={<CloseCircleOutlined />}
              type="danger"
              onClick={() => {
                setVisibleStatus(true);
                setRowData(record);
              }}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  const getServiceAvailable = async (e) => {
    setLoading(true);
    try {
      const { data } = await getService(e);
      if (data.message === "Success") {
        const dokumen = data.data;
        setDataService(dokumen);
        console.log("data service", { dokumen });
      }
    } catch (e) {
      console.warn("error get doctor", e);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const getLicense = async (e) => {
    setLoading(true);
    try {
      const { data } = await getListLisensi(e);
      if (data.message === "Success") {
        const dokumen = data.data;
        setDataLisensi(dokumen);
      }
    } catch (e) {
      console.warn("error get doctor", e);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const getApi = async () => {
      setLoading(true);
      try {
        const { data } = await getListPartner(pagination, parameter);
        if (data.message === "Success") {
          const dataFilter = data.data?.filter((e, i) => {
            return e.registration_status !== 'pending'
          });
          const filter = dataFilter.filter((v, i) => {
            return (v.no = i + 1)
          })
          // const totalData = data.data.count;
          const total = await countPartner();
          setTotal(total);
          setData(filter);
        }
      } catch (e) {
        console.warn("error get doctor", e);
        message.error("Terjadi kesalahan, silakan coba lagi");
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };
    getApi();
  }, [show, parameter, pagination, trigger]);

  const putDoctorProfile = async () => {
    setLoading(true);
    try {
      const data = await putPartner(sendData);
      if (data.data.message === "Success") {
        message.success("Data berhasil diperbarui");
      }
      console.log("data update", { data });
    } catch (e) {
      console.warn("error put doctor", e);
      message.error("Terjadi kesalahan, silakan coba lagi");
      setLoading(false);
    } finally {
      setLoading(false);
      setSendData({});
    }
  };

  const updateStatus = async () => {
    setLoading(true);
    const id = rowData.id;
    const body = {
      price_level_id: rowData.price_level_id,
      registration_number: rowData.registration_number,
      registration_code: rowData.registration_code,
      registration_interview_date: rowData.registration_interview_date,
      registration_expire: rowData.registration_expire,
      registration_status: "rejected",
      created_at: rowData.created_at,
      updated_at: rowData.updated_at,
      is_on_service: rowData.is_on_service,
      doctor_type: rowData.doctor_type,
      name: rowData.name,
      phone: rowData.phone,
      email: rowData.email,
      status: "inactive",
      otp_created: rowData.otp_created,
      is_visible: rowData.is_visible,
      location: rowData.location,
      device: rowData.device,
      verification_token: rowData.verification_token,
      verification_expired: rowData.verification_expired,
    };
    try {
      const data = await putStatusPartner(body, "reject", id);
      console.log("data", { data });
      if (data.data.message === "Success") {
        message.success("Data Berhasil Diperbaharui");
      }
      console.log("data update", { data });
    } catch (e) {
      console.warn("error put doctor", e);
      message.error("Terjadi Kesalahan, Silahkan Coba Nanti");
      setLoading(false);
    } finally {
      setLoading(false);
      setTrigger(!trigger);
      setVisibleStatus(false);
    }
  };

  const changeHandler = (e) => {
    const temp = {
      ...rowData,
      ...e,
    };
    const body = {
      ...sendData,
      ...e,
      id: temp.id,
      location: `(${temp.location.x},${temp.location.y})`,
    };
    delete body.icon_url
    console.log({ body, temp, e });
    setRowData(temp);
    setSendData(body);
  };

  return (
    <>
      <div>
        {show ? (
          <DataDoctor
            data={rowData}
            dataService={dataService}
            disabled={view ? true : false}
            dataLisensi={dataLisensi}
            onChange={(e) => changeHandler(e)}
            loading={loading}
            back={(e) => {
              setEdit(e);
              setView(e);
              setShow(e);
            }}
            putDoctor={() => putDoctorProfile()}
            getLicense={(e) => getLicense(e)}
            reloadService={(e) => getServiceAvailable(e)}
            flagView={view}
            flagEdit={edit}
          />
        ) : (
          <TableCustom
            columns={column}
            data={dataTable}
            total={total}
            loading={loading}
            setParameter={(e) => setParameter(e)}
            except={["no", "operation"]}
            setPagination={(e) => setPagination(e)}
            startInOne={true}
          />
        )}
      </div>
      <Modal
        title="
                Apakah anda yakin ingin menonaktifkan Partner ?"
        width={800}
        visible={visibleStatus}
        closable={false}
        footer={null}
      >
        <div>
          <div>
            <label>
              Jika menekan tombol klik Nonaktif maka Partner menjadi nonaktif.
            </label>
          </div>
          <br />
          <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <div style={{ marginLeft: "auto" }}>
              <Button onClick={() => setVisibleStatus(false)}>Batalkan</Button>
            </div>
            <div style={{ marginLeft: "10px" }}>
              <Button
                type="primary"
                loading={loading}
                onClick={() => updateStatus()}
              >
                Nonaktif
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ListDoctor;
